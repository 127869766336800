<template>
    <v-data-table
        :headers="headers"
        :items="data"
        hide-default-footer
        disable-sort
        dense
    ></v-data-table>
</template>

<script>
export default {
    props: {
        data: Array,
    },
    data() {
        return {
            headers: [
                { text: "Busnummers", value: "bus_numbers" },
                { text: "Tijdstip vertrek", value: "time" },
                { text: "Van", value: "from" },
                { text: "Naar", value: "to" },
                { text: "Aantal FTE", value: "amount_workers" },
                { text: "Aantal bussen", value: "amount" },
                { text: "Capaciteit", value: "capacity" },
                { text: "Buffer", value: "buffer" },
            ],
        };
    },
};
</script>