var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"items":_vm.logs,"headers":_vm.headers,"search":_vm.search,"loading":_vm.loadingLogs,"loading-text":"Rapportage ophalen..."},scopedSlots:_vm._u([_vm._l((_vm.filters),function(col,i){return {key:("header." + i),fn:function(ref){
var header = ref.header;
return [_c('div',{key:i + '-text',staticStyle:{"display":"inline-block","padding":"16px 0"}},[_vm._v(" "+_vm._s(header.text)+" ")]),_c('div',{key:i + '-filter',staticStyle:{"float":"right","margin-top":"10px"}},[_c('v-menu',{staticStyle:{"position":"absolute","right":"0"},attrs:{"close-on-content-click":false,"nudge-width":200,"offset-y":"","transition":"slide-y-transition","left":"","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"indigo","icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.activeFilters[header.value] &&
                                _vm.activeFilters[header.value].length <
                                    _vm.filters[header.value].length
                                    ? 'red'
                                    : 'default'}},[_vm._v(" mdi-filter-variant ")])],1)]}}],null,true)},[_c('v-list',{staticClass:"pa-0",attrs:{"flat":"","dense":""}},[_c('v-list-item-group',{staticClass:"py-2",attrs:{"multiple":""},model:{value:(_vm.activeFilters[header.value]),callback:function ($$v) {_vm.$set(_vm.activeFilters, header.value, $$v)},expression:"activeFilters[header.value]"}},[_vm._l((_vm.filters[header.value]),function(item){return [_c('v-list-item',{key:("" + item),attrs:{"value":item,"ripple":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var active = ref.active;
                                    var toggle = ref.toggle;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"true-value":item,"color":"primary","ripple":false,"dense":""},on:{"click":toggle}})],1),(header.value == 'retour')?_c('v-list-item-content',[(item == 1)?_c('v-list-item-title',[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-checkbox-marked-circle")])],1):_c('v-list-item-title',[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close-circle")])],1)],1):(header.value == 'status')?_c('v-list-item-content',[_c('v-list-item-title',[_c('v-icon',{attrs:{"color":_vm.statusColor(item)}},[_vm._v(" mdi-circle ")])],1)],1):(
                                            header.value == 'worker.agency'
                                        )?_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item .charAt(0) .toUpperCase() + item.slice(1))+" ")])],1):_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item)}})],1)]}}],null,true)})]})],2)],1)],1)],1)]}}}),{key:"item.status",fn:function(ref){
                                        var item = ref.item;
return [_c('v-badge',{attrs:{"color":_vm.statusColor(item.status)}})]}},{key:"item.worker.id",fn:function(ref){
                                        var item = ref.item;
return [(item.worker.first_name || item.worker.first_name)?_c('label',[_vm._v(" "+_vm._s(item.worker.first_name)+" "+_vm._s(item.worker.last_name)+" ("+_vm._s(item.worker.id)+") ")]):_c('label',[_vm._v("Niet gekend")])]}},{key:"item.worker.agency",fn:function(ref){
                                        var item = ref.item;
return [(item.worker)?_c('label',[_vm._v(" "+_vm._s(item.worker.agency.charAt(0).toUpperCase() + item.worker.agency.slice(1))+" ")]):_vm._e()]}},{key:"item.retour",fn:function(ref){
                                        var item = ref.item;
return [(item.retour)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-checkbox-marked-circle")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close-circle")])]}},{key:"item.created_at",fn:function(ref){
                                        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseDate(item.created_at))+" ")]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }