<template>
    <v-data-table
        :items="logs"
        :headers="headers"
        :search="search"
        :loading="loadingLogs"
        loading-text="Rapportage ophalen..."
    >
        <template
            v-for="(col, i) in filters"
            v-slot:[`header.${i}`]="{ header }"
        >
            <div
                style="display: inline-block; padding: 16px 0"
                :key="i + '-text'"
            >
                {{ header.text }}
            </div>

            <div style="float: right; margin-top: 10px" :key="i + '-filter'">
                <v-menu
                    :close-on-content-click="false"
                    :nudge-width="200"
                    offset-y
                    transition="slide-y-transition"
                    left
                    fixed
                    style="position: absolute; right: 0"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="indigo"
                            icon
                            small
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon
                                small
                                :color="
                                    activeFilters[header.value] &&
                                    activeFilters[header.value].length <
                                        filters[header.value].length
                                        ? 'red'
                                        : 'default'
                                "
                            >
                                mdi-filter-variant
                            </v-icon>
                        </v-btn>
                    </template>

                    <v-list flat dense class="pa-0">
                        <v-list-item-group
                            multiple
                            v-model="activeFilters[header.value]"
                            class="py-2"
                        >
                            <template v-for="item in filters[header.value]">
                                <v-list-item
                                    :key="`${item}`"
                                    :value="item"
                                    :ripple="false"
                                >
                                    <template
                                        v-slot:default="{ active, toggle }"
                                    >
                                        <v-list-item-action>
                                            <v-checkbox
                                                :input-value="active"
                                                :true-value="item"
                                                @click="toggle"
                                                color="primary"
                                                :ripple="false"
                                                dense
                                            ></v-checkbox>
                                        </v-list-item-action>

                                        <!-- Retour filter -->
                                        <v-list-item-content
                                            v-if="header.value == 'retour'"
                                        >
                                            <v-list-item-title v-if="item == 1">
                                                <v-icon color="success"
                                                    >mdi-checkbox-marked-circle</v-icon
                                                >
                                            </v-list-item-title>
                                            <v-list-item-title v-else>
                                                <v-icon color="error"
                                                    >mdi-close-circle</v-icon
                                                ></v-list-item-title
                                            >
                                        </v-list-item-content>

                                        <!-- Status filter -->
                                        <v-list-item-content
                                            v-else-if="header.value == 'status'"
                                        >
                                            <v-list-item-title>
                                                <v-icon
                                                    :color="statusColor(item)"
                                                >
                                                    mdi-circle
                                                </v-icon>
                                            </v-list-item-title>
                                        </v-list-item-content>

                                        <!-- Agency filter -->
                                        <v-list-item-content
                                            v-else-if="
                                                header.value == 'worker.agency'
                                            "
                                        >
                                            <v-list-item-title>
                                                {{
                                                    item
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                    item.slice(1)
                                                }}
                                            </v-list-item-title>
                                        </v-list-item-content>

                                        <!-- Bus number filter -->
                                        <v-list-item-content v-else>
                                            <v-list-item-title v-text="item">
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </template>
                                </v-list-item>
                            </template>
                        </v-list-item-group>
                    </v-list>
                </v-menu>
            </div>
        </template>

        <!-- Status -->
        <template v-slot:[`item.status`]="{ item }">
            <v-badge :color="statusColor(item.status)"></v-badge>
        </template>

        <!-- Worker -->
        <template v-slot:[`item.worker.id`]="{ item }">
            <label v-if="item.worker.first_name || item.worker.first_name">
                {{ item.worker.first_name }}
                {{ item.worker.last_name }} ({{ item.worker.id }})
            </label>

            <label v-else>Niet gekend</label>
        </template>

        <!-- Agency -->
        <template v-slot:[`item.worker.agency`]="{ item }">
            <label v-if="item.worker">
                {{
                    item.worker.agency.charAt(0).toUpperCase() +
                    item.worker.agency.slice(1)
                }}
            </label>
        </template>

        <!-- Retour bus -->
        <template v-slot:[`item.retour`]="{ item }">
            <v-icon v-if="item.retour" color="success"
                >mdi-checkbox-marked-circle</v-icon
            >
            <v-icon v-else color="error">mdi-close-circle</v-icon>
        </template>

        <!-- Scan time -->
        <template v-slot:[`item.created_at`]="{ item }">
            {{ parseDate(item.created_at) }}
        </template>
    </v-data-table>
</template>

<script>
var dateFormat = require("dateformat");

export default {
    props: {
        search: String,
        date: String,
    },
    data() {
        return {
            filters: {
                status: [],
                retour: [],
                shift_start: [],
                "bus.bus_number": [],
                "worker.agency": [],
            },
            activeFilters: {},

            logs: [],
            loadingLogs: true,
        };
    },
    computed: {
        headers() {
            return [
                {
                    text: "Status",
                    value: "status",
                    filter: (value) => {
                        return this.activeFilters.status
                            ? this.activeFilters.status.includes(value)
                            : true;
                    },
                    width: "114px",
                },
                {
                    text: "Memo",
                    value: "memo",
                },
                // d-none (space required!) --> hide header but make name searchable
                {
                    text: "First name",
                    value: "worker.first_name",
                    align: " d-none",
                },
                {
                    text: "Last name",
                    value: "worker.last_name",
                    align: " d-none",
                },
                { text: "Uitzendkracht", value: "worker.id" },
                {
                    text: "Uitzendbureau",
                    value: "worker.agency",
                    filter: (value) => {
                        return this.activeFilters["worker.agency"]
                            ? this.activeFilters["worker.agency"].includes(
                                  value
                              )
                            : true;
                    },
                    width: "158px",
                },
                { text: "Controleur", value: "inspector.username" },
                {
                    text: "Shift",
                    value: "shift_start",
                    filter: (value) => {
                        return this.activeFilters["shift_start"]
                            ? this.activeFilters["shift_start"].includes(value)
                            : true;
                    },
                    width: "114px",
                },
                {
                    text: "Busnummer",
                    value: "bus.bus_number",
                    filter: (value) => {
                        return this.activeFilters["bus.bus_number"]
                            ? this.activeFilters["bus.bus_number"].includes(
                                  value
                              )
                            : true;
                    },
                    width: "150px",
                },
                {
                    text: "Retour",
                    value: "retour",
                    filter: (value) => {
                        return this.activeFilters.retour
                            ? this.activeFilters.retour.includes(value)
                            : true;
                    },
                    width: "120px",
                },
                { text: "Scantijd", value: "created_at" },
            ];
        },
    },
    methods: {
        parseDate(date) {
            return dateFormat(new Date(date), "yyyy-mm-dd HH:MM:ss");
        },
        statusColor(status) {
            if (status == 0) return "error";
            else if (status == 1) return "success";
            else return "warning";
        },
        initFilters() {
            for (const col in this.filters) {
                this.filters[col] = this.logs
                    .map((d) => {
                        if (d["inspector"] == null) {
                            d["inspector"] = [];
                            d["inspector"]["username"] = "Niet gekend";
                        }

                        if (d["bus"] == null) {
                            d["bus"] = [];
                            d["bus"]["bus_number"] = "Niet gekend";
                        }

                        if (d["worker"] == null) {
                            d["worker"] = [];
                            d["worker"]["agency"] = "Niet gekend";
                        }

                        if (col == "bus.bus_number")
                            return d["bus"]["bus_number"];
                        else if (col == "worker.agency") {
                            return d["worker"]["agency"];
                        } else return d[col];
                    })
                    .filter((value, index, self) => {
                        return self.indexOf(value) === index;
                    });
            }

            this.activeFilters = Object.assign({}, this.filters);
        },
    },
    watch: {
        date: {
            handler: function (value) {
                this.logs = [];
                this.loadingLogs = true;

                this.$store
                    .dispatch("validation/index", value)
                    .then((logs) => {
                        this.logs = logs;

                        this.initFilters();
                    })
                    .finally(() => {
                        this.loadingLogs = false;
                    });
            },
        },
    },
};
</script>

<style>
.v-list--dense .v-list-item,
.v-list-item--dense {
    min-height: 20px !important;
    height: 2rem;
}

.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
    margin-right: 0.5rem !important;
}

.v-list-item--link {
    transition: background-color 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.v-list-item--link:hover {
    background-color: rgba(0, 0, 0, 0.13);
}
</style>