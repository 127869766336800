<template>
    <v-card id="bus-grouping">
        <v-card-text>
            <h3 class="mb-2">Bus {{ bus.bus_number }}</h3>

            <!-- Operations -->
            <v-chip
                v-for="(amount, operation) in bus.operations"
                :key="operation"
                class="mr-2 mb-2"
                color="red"
                text-color="white"
                small
                @click="
                    confirmed
                        ? (snackbar = true)
                        : moveOperation(operation, amount)
                "
            >
                {{ operation }}: {{ amount }}
            </v-chip>

            <!-- Free spots -->
            <v-chip class="mr-2 mb-2" color="green" text-color="white" small>
                Vrije plaatsen: {{ this.$busCapacity - bus.total }}
            </v-chip>

            <br />

            <!-- Bus visualisation -->
            <div id="bus-visualisation" class="bus mb-2">
                <div class="d-inline" v-for="i in $busCapacity" :key="i">
                    <v-icon
                        class="mr-1"
                        :class="{ 'mb-4': i <= 22 && i > 11 }"
                        :color="freeSpot(i) ? 'green' : 'gray'"
                    >
                        mdi-seat-passenger
                    </v-icon>

                    <br v-if="i % 11 == 0" />
                </div>
            </div>

            <!-- Actions -->
            <div id="actions">
                <add-worker-dialog
                    :type="type"
                    :bus="bus.id"
                    :disabled="bus.total == $busCapacity"
                    :confirmed="confirmed"
                />

                <bus-workers-detailed :type="type" :bus="bus.id" />
            </div>
        </v-card-text>

        <v-snackbar v-model="snackbar" color="error" :timeout="5000" top right>
            <v-icon class="mr-2">mdi-alert-circle</v-icon>
            <label>
                Operation kan niet verplaatst worden na het bevestigen van de
                bus indeling.
            </label>

            <template v-slot:action="{ attrs }">
                <v-btn
                    icon
                    x-small
                    v-bind="attrs"
                    @click.native="snackbar = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </v-card>
</template>

<script>
import { EventBus } from "@/event-bus.js";

import AddWorkerDialog from "@/components/planning/AddWorkerDialog.vue";
import BusWorkersDetailed from "@/components/planning/BusWorkersDetailed.vue";

export default {
    props: {
        type: String,
        bus: Object,
        confirmed: Boolean,
    },
    components: {
        AddWorkerDialog,
        BusWorkersDetailed,
    },
    data() {
        return {
            snackbar: false,
        };
    },
    methods: {
        freeSpot(i) {
            return this.$transposedBusLayout[i - 1] > this.bus.total;
			//return i > this.bus.total
        },
        moveOperation(operation, amount) {
            EventBus.$emit("move-operation-" + this.type, {
                bus: this.bus,
                operation: { operation: operation, amount: amount },
            });
        },
    },
};
</script>

<style>
.bus {
    border: solid lightgray 1px;
    border-radius: 5px;
    padding: 10px;
    display: inline-block;
}
</style>