<template>
    <v-dialog v-model="dialog">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="text-none mr-2"
                small
                depressed
                v-bind="attrs"
                v-on="on"
            >
                Details
            </v-btn>
        </template>

        <v-card>
            <v-btn
                class="dialog__close"
                icon
                x-small
                @click.native="dialog = false"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-card-title>Details</v-card-title>

            <v-card-text>
                <v-row>
                    <v-col cols="4">
                        <v-text-field
                            v-model="search"
                            class="pt-0"
                            append-icon="mdi-magnify"
                            label="Zoeken"
                            hide-details
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-data-table
                    :headers="headers"
                    :items="details"
                    :search="search"
                    :loading="busWorkersLoading"
                    loading-text="Bus details ophalen..."
                >
                    <template v-slot:[`item.worker.agency`]="{ item }">
                        {{ capitalize(item.worker.agency) }}
                    </template>

                    <template v-slot:[`item.manual`]="{ item }">
                        <v-icon v-if="!item.manual" color="success" small>
                            mdi-checkbox-marked-circle
                        </v-icon>

                        <v-icon v-else color="error" small
                            >mdi-close-circle</v-icon
                        >
                    </template>

                    <template v-slot:[`item.confirmed`]="{ item }">
                        <v-icon v-if="item.confirmed" color="success" small>
                            mdi-checkbox-marked-circle
                        </v-icon>

                        <v-icon v-else color="error" small
                            >mdi-close-circle</v-icon
                        >
                    </template>

                    <template v-slot:[`item.exclude_retour`]="{ item }">
                        <v-icon v-if="item.exclude_retour" color="error" small>
                            mdi-close-circle
                        </v-icon>

                        <v-icon v-else color="success" small>
                            mdi-checkbox-marked-circle
                        </v-icon>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <qr-dialog :worker="item.worker" />

                        <v-btn icon @click="openDeleteConfirmation(item.id)">
                            <v-icon color="red" small> mdi-delete </v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <delete-confirmation
            ref="deleteDialog"
            title="Bent u zeker?"
            text="Bent u zeker dat u deze uitzendkracht wilt verwijderen van de bus?"
            @confirm="deleteWorkerFromBus"
        />
    </v-dialog>
</template>

<script>
import { EventBus } from "@/event-bus.js";

import QrDialog from "@/components/workers/QrDialog.vue";
import DeleteConfirmation from "@/components/DeleteConfirmation.vue";

export default {
    props: {
        type: String,
        bus: Number,
        confirmed: Boolean,
    },
    components: {
        DeleteConfirmation,
        QrDialog,
    },
    data() {
        return {
            dialog: false,

            search: null,
            busWorkersLoading: true,
            details: [],

            headers: [
                { text: "Nummer", value: "worker.id" },
                { text: "Operatie", value: "operation" },
                { text: "Activity", value: "activity" },
                { text: "Voornaam", value: "worker.first_name" },
                { text: "Achternaam", value: "worker.last_name" },
                { text: "Telefoon", value: "worker.phone" },
                { text: "Uitzendbureau", value: "worker.agency" },
                { text: "Automatisch", value: "manual" },
                { text: "Gecontacteerd", value: "confirmed" },
                { text: "Retour", value: "exclude_retour" },
                { text: "", value: "actions", sortable: false },
            ],
        };
    },
    methods: {
        deleteWorkerFromBus(id) {
            this.$store.dispatch("planning/deleteWorker", id).then(() => {
                EventBus.$emit("reload-bus-grouping-" + this.type);
            });
        },
        openDeleteConfirmation(id) {
            this.$refs.deleteDialog.setId(id);
            this.$refs.deleteDialog.open();
        },
        capitalize(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
    },
    watch: {
        dialog(value) {
            if (!value || !this.busWorkersLoading) return;

            this.$store
                .dispatch("planning/busGroupingDetailed", this.bus)
                .then((resp) => {
                    this.details = resp;
                })
                .finally(() => {
                    this.busWorkersLoading = false;
                });
        },
    },
};
</script>