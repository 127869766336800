<template>
    <v-dialog v-model="dialog" width="600">
        <v-card>
            <v-btn class="dialog__close" icon x-small @click.native="close()">
                <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-card-title> Controleur toevoegen </v-card-title>

            <form v-on:submit.prevent="create">
                <v-card-text class="pt-0">
                    <v-row>
                        <v-col cols="6">
                            <v-text-field
                                v-model="inspector.username"
                                label="Gebruikersnaam"
                                type="text"
                                required
                                hide-details
                            ></v-text-field
                        ></v-col>

                        <v-col cols="6">
                            <v-text-field
                                v-model="inspector.password"
                                label="Wachtwoord"
                                type="text"
                                required
                                hide-details
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12">
                            <v-text-field
                                v-model="inspector.memo"
                                label="Memo"
                                type="text"
                                hide-details
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <p
                        v-if="duplicateError"
                        class="text-left red--text ma-0 mt-2"
                    >
                        Gebruikersnaam reeds in gebruik.
                    </p>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        type="submit"
                        color="success"
                        text
                        :loading="creating"
                    >
                        Toevoegen
                    </v-btn>
                </v-card-actions>
            </form>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            dialog: false,
            creating: false,
            duplicateError: false,

            inspector: {
                username: null,
                password: null,
                memo: null,
            },
        };
    },
    methods: {
        create() {
            this.duplicateError = false;
            this.creating = true;

            this.$store
                .dispatch("inspector/create", this.inspector)
                .then((inspector) => {
                    this.$emit("inspector", inspector);

                    this.close();
                })
                .catch((error) => {
                    if (error.status == 409) this.duplicateError = true;
                })
                .finally(() => {
                    this.creating = false;
                });
        },
        open() {
            this.dialog = true;
        },
        close() {
            this.dialog = false;

            this.inspector.username = null;
            this.inspector.password = null;
            this.inspector.memo = null;
        },
    },
};
</script>

<style>
</style>