import Vue from "vue";
import Vuex from "vuex";

import axios from "axios";

Vue.use(Vuex);

const workerModule = {
	namespaced: true,
	actions: {
		// Get all workers for current authenticated agency
		// Each agency will only see their own workers (except from admin)
		index(context, search = null) {
			return new Promise((resolve, reject) => {
				search = search ? "?search=" + search : "";

				axios.get("/api/worker" + search).then((resp) => {
					resolve(resp.data);
				}).catch((error) => {
					reject(error);
				});
			});
		},
		create(context, worker) {
			return new Promise((resolve, reject) => {
				axios.post("/api/worker", worker).then((resp) => {
					resolve(resp.data);
				}).catch((error) => {
					reject(error.response);
				});
			});
		},
		delete(context, id) {
			return new Promise((resolve, reject) => {
				axios.delete("/api/worker/" + id).then(() => {
					resolve();
				}).catch((error) => {
					reject(error);
				});
			});
		},
		export() {
			return new Promise((resolve, reject) => {
				axios.get("/api/worker/export", {
					responseType: 'blob'
				}).then((resp) => {
					resolve(resp.data);
				}).catch((error) => {
					reject(error);
				});
			});
		},
	},
};

export default workerModule;
