import Vue from 'vue';
import VueRouter from 'vue-router';

import Login from '@/views/Login.vue';
import ForgotPassword from '@/views/ForgotPassword.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import Badge from '@/views/Badge.vue';
import BadgeActivator from '@/views/BadgeActivator.vue';

import Dashboard from '@/views/Dashboard.vue';
import Planning from '@/views/dashboard/Planning.vue';
import Statistics from '@/views/dashboard/Statistics.vue';
import BusReport from '@/views/dashboard/BusReport.vue';
import Inspectors from '@/views/dashboard/Inspectors.vue';
import Workers from '@/views/dashboard/Workers.vue';

Vue.use(VueRouter);

const routes = [
	{
		path: '/login',
		name: 'Login',
		component: Login,
	},
	{
		path: '/wachtwoord-vergeten',
		name: 'ForgotPassword',
		component: ForgotPassword,
	},
	{
		path: '/wachtwoord-reset',
		name: 'ResetPassword',
		component: ResetPassword,
	},
	{
		path: '/activate',
		name: 'BadgeActivator',
		component: BadgeActivator,
	},
	{
		path: '/badge/:hash',
		name: 'Badge',
		component: Badge,
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		redirect: { name: 'Planning' },
		component: Dashboard,
		children: [
			{
				path: 'planning',
				name: 'Planning',
				component: Planning
			},
			{
				path: 'statistieken',
				name: 'Statistics',
				component: Statistics,
			},
			{
				path: 'rapportage',
				name: 'BusReport',
				component: BusReport
			},
			{
				path: 'uitzendkrachten',
				name: 'Workers',
				component: Workers,
			},
			{
				path: 'controleurs',
				name: 'Inspectors',
				component: Inspectors,
			},
		],
		meta: { requiresAuth: true }
	},
	{
		// Redirect to login when route not found (or empty route)
		path: '/*',
		meta: { requiresAuth: true }
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

export default router;
