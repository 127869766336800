<template>
    <v-dialog v-model="dialog" width="500" persistent>
        <v-card>
            <v-btn
                class="dialog__close"
                icon
                x-small
                @click.native="dialog = false"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-card-title>Lijst importeren</v-card-title>

            <form v-on:submit.prevent="upload">
                <v-card-text class="pb-2 pt-2">
                    <v-file-input
                        v-model="file"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        label="File input"
                        required
                        :hide-details="type == 'order'"
                    ></v-file-input>

                    <v-checkbox
                        v-if="type == 'order'"
                        v-model="early"
                        class="mt-3"
                        label="Busplanning vroege dienst genereren"
                        hide-details
                        dense
                    ></v-checkbox>

                    <v-checkbox
                        v-if="type == 'order'"
                        v-model="late"
                        class="mt-0"
                        label="Busplanning late & nachtdienst genereren"
                        hide-details
                        dense
                    ></v-checkbox>
                </v-card-text>

                <v-card-actions>
                    <v-btn text :disabled="uploading" @click="dialog = false">
                        Annuleren
                    </v-btn>

                    <v-spacer></v-spacer>

                    <v-btn
                        type="submit"
                        color="success"
                        text
                        :loading="uploading"
                    >
                        Opladen
                    </v-btn>
                </v-card-actions>
            </form>
        </v-card>

        <order-new-bus-dialog ref="orderNewBusDialog" @ordered="upload" />
    </v-dialog>
</template>

<script>
import { EventBus } from "@/event-bus.js";

import OrderNewBusDialog from "@/components/planning/OrderNewBusDialog.vue";

export default {
    props: {
        type: String,
        date: String,
    },
    components: {
        OrderNewBusDialog,
    },
    data() {
        return {
            dialog: false,
            uploading: false,

            file: null,
            order: false,
            early: false,
            late: false,
        };
    },
    mounted() {
        if (this.type == "order") this.order = true;
        else if (this.type == "early") this.early = true;
        else if (this.type == "late") this.late = true;
    },
    methods: {
        upload() {
            if (!this.file) return;

            this.uploading = true;

            let formData = new FormData();

            let types = [];
            if (this.order) types.push("order");
            if (this.early) types.push("early");
            if (this.late) types.push("late");

            formData.append("file", this.file);
            formData.append("date", this.date);
            formData.append("types", JSON.stringify(types));

            this.$store
                .dispatch("planning/import", formData)
                .then(() => {
                    // Reload components after import
                    EventBus.$emit("reload-bus-order");

                    if (this.order || this.early)
                        EventBus.$emit("reload-bus-grouping-early");

                    if (this.order || this.late)
                        EventBus.$emit("reload-bus-grouping-late");

                    this.close();
                })
                .catch((error) => {
                    // Bus order does not comply anymore
                    if (error.status == 418)
                        this.$refs.orderNewBusDialog.open(error.data);
                })
                .finally(() => {
                    this.uploading = false;
                });
        },
        open() {
            this.dialog = true;
        },
        close() {
            this.file = null;

            if (this.type == "order") {
                this.early = false;
                this.late = false;
            }

            this.dialog = false;
        },
    },
};
</script>