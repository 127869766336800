<template>
    <div id="dashboard">
        <!-- Navigation bar -->
        <v-navigation-drawer width="280" permanent app>
            <v-img
                :src="require(`@/assets/logo.jpg`)"
                class="mt-4 mx-auto"
                max-width="120"
            />

            <v-divider class="ma-4" />

            <v-list nav>
                <v-list-item
                    v-for="item in items"
                    :key="item.title"
                    :to="item.link"
                    active-class="blue--text"
                    link
                >
                    <v-list-item-icon class="pl-4">
                        <v-icon dense>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title class="nav-item">
                            {{ item.title }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <template v-slot:append>
                <div class="mb-8 text-center">
                    <v-btn
                        outlined
                        rounded
                        color="red"
                        :loading="loggingOut"
                        @click="signOut()"
                        >Uitloggen</v-btn
                    >
                </div>
            </template>
        </v-navigation-drawer>

        <!-- Content -->
        <v-main>
            <v-container>
                <router-view></router-view>
            </v-container>
        </v-main>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loggingOut: false,
            items: [
                {
                    title: "Planning",
                    icon: "mdi-calendar",
                    link: "/dashboard/planning",
                },
                {
                    title: "Statistieken",
                    icon: "mdi-chart-line",
                    link: "/dashboard/statistieken",
                    admin: true,
                },
                {
                    title: "Rapportage",
                    icon: "mdi-chart-line",
                    link: "/dashboard/rapportage",
                },
                {
                    title: "Controleurs",
                    icon: "mdi-account-check",
                    link: "/dashboard/controleurs",
                },
                {
                    title: "Uitzendkrachten",
                    icon: "mdi-account-hard-hat",
                    link: "/dashboard/uitzendkrachten",
                },
            ],
        };
    },
    mounted() {
        this.items = [
            {
                title: "Planning",
                icon: "mdi-calendar",
                link: "/dashboard/planning",
            },
            {
                title: "Rapportage",
                icon: "mdi-chart-line",
                link: "/dashboard/rapportage",
            },
            {
                title: "Controleurs",
                icon: "mdi-account-check",
                link: "/dashboard/controleurs",
            },
            {
                title: "Uitzendkrachten",
                icon: "mdi-account-hard-hat",
                link: "/dashboard/uitzendkrachten",
            },
        ];

        // Add statistics for admin account (Werner)
        if (this.$store.getters.isAdmin) {
            this.items.splice(1, 0, {
                title: "Statistieken",
                icon: "mdi-chart-bar",
                link: "/dashboard/statistieken",
            });
        }
    },
    methods: {
        signOut() {
            this.loggingOut = true;
            this.$store.dispatch("signOut");
        },
    },
};
</script>

<style scoped>
.container {
    padding: 40px;
}

.nav-item {
    font-weight: 500;
    color: #252525;
}
</style>
