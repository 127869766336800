<template>
    <v-container fill-height fluid>
        <v-row v-if="worker" align="center" justify="center">
            <badge :worker="worker" :download="true" />
        </v-row>
    </v-container>
</template>

<script>
import Badge from "@/components/workers/Badge.vue";

export default {
    data() {
        return {
            worker: null,
        };
    },
    components: {
        Badge,
    },
    mounted() {
        // Retrieve worker information by hash
        this.$store
            .dispatch("badge/digital", this.$route.params.hash)
            .then((worker) => {
                this.worker = worker;
            });
    },
};
</script>