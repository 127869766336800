<template>
    <v-dialog v-model="dialog" width="500">
        <v-card>
            <v-btn
                class="dialog__close"
                icon
                x-small
                @click.native="dialog = false"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-card-title>{{ title }}</v-card-title>

            <v-card-text class="pb-2 pt-2">
                {{ text }}
            </v-card-text>

            <v-card-actions>
                <v-btn text @click="dialog = false" :disabled="deleting">
                    Annuleren
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn color="error" text @click="confirm" :loading="deleting">
                    Verwijderen
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        title: String,
        text: String,
    },
    data() {
        return {
            id: null,
            deleting: false,
            dialog: false,
        };
    },
    methods: {
        confirm() {
            this.deleting = true;

            this.$emit("confirm", this.id);
        },
        setId(id) {
            this.id = id;
        },
        open() {
            this.dialog = true;
        },
        close() {
            this.id = null;
            this.deleting = false;
            this.dialog = false;
        },
    },
};
</script>

<style>
</style>