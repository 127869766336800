<template>
    <v-dialog v-model="dialog" width="500" persistent>
        <v-card>
            <v-btn
                class="dialog__close"
                icon
                x-small
                @click.native="dialog = false"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-card-title>Bent u zeker?</v-card-title>

            <v-card-text class="pb-2 pt-2">
                Na bevestiging kunnen er geen aanpassingen meer gedaan worden.
                Aanpassingen zullen manueel doorgevoerd moeten worden.
            </v-card-text>

            <v-card-actions>
                <v-btn text @click="dialog = false" :disabled="confirming">
                    Annuleren
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn
                    color="success"
                    text
                    @click="confirm"
                    :loading="confirming"
                >
                    Bevestig
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "ConfirmDialog",
    props: {
        grouping: Object,
    },
    data() {
        return {
            dialog: false,
            confirming: false,
        };
    },
    computed: {
        shiftException() {
            let message = "";

            let shifts = this.grouping.shifts;
            for (let i = 0; i < shifts.length; i++) {
                if (shifts[i].buses_all.length == 1) {
                    message += message.length ? " en " : "";
                    message += `<strong>${shifts[i].shift_time}</strong>`;
                }
            }

            return message;
        },
    },
    methods: {
        confirm() {
            this.confirming = true;

            this.$emit("confirm");
        },
        open() {
            this.dialog = true;
        },
        close() {
            this.confirming = false;
            this.dialog = false;
        },
    },
};
</script>

<style>
</style>