import Vue from "vue";
import axios from "axios";
import VueHtml2Canvas from 'vue-html2canvas';
import HighchartsVue from 'highcharts-vue'

import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import TransposedBusLayout from "./plugins/transposed-bus-layout";

import "./assets/css/main.min.css";

Vue.prototype.$busCapacity = 44;

Vue.use(VueHtml2Canvas);
Vue.use(TransposedBusLayout)
Vue.use(HighchartsVue)

//Vue.config.productionTip = false;

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.interceptors.response.use((response) => response, (error) => {
	// Redirect back to login when status is 401
	if (error.response.status == 401 && router.history.current.matched[0].meta.requiresAuth)
		router.push({ name: 'Login' });

	throw error;
});

new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App),
}).$mount("#app");
