<template>
    <v-app>
        <!-- Fullscreen Loader -->
        <v-overlay v-if="checkAuthenticated">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <!-- Application -->
        <router-view v-else></router-view>
    </v-app>
</template>

<script>
export default {
    name: "App",
    data() {
        return {
            initialCheck: false,
        };
    },
    computed: {
        checkAuthenticated() {
            return !this.$store.getters.authenticated && !this.initialCheck;
        },
        authenticated() {
            return this.$store.getters.authenticated;
        },
    },
    mounted() {
        // Do not check authentication if on Badge or BadgeActivator
        if (
            this.$route.matched[0].name === "Badge" ||
            this.$route.matched[0].name === "BadgeActivator"
        ) {
            this.initialCheck = true;
        } else {
            this.$store.dispatch("me").finally(() => {
                this.initialCheck = true;
            });
        }
    },
    watch: {
        authenticated(authenticated) {
            if (authenticated) {
                if (
                    this.$route.matched.length == 0 ||
                    this.$route.matched[0].name !== "Dashboard"
                )
                    this.$router.push({ name: "Planning" });
            } else {
                this.$router.push({ name: "Login" });
            }
        },
    },
};
</script>

<style>
.v-application {
    background-color: rgb(241, 241, 241) !important;
}
</style>
