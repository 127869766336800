<template>
    <div id="inspectors">
        <h2 class="mb-2">Controleurs</h2>

        <v-card elevation="1">
            <v-card-title>
                <v-row>
                    <v-col cols="4">
                        <v-text-field
                            v-model="search"
                            class="pt-0"
                            append-icon="mdi-magnify"
                            label="Zoeken"
                            hide-details
                        ></v-text-field>
                    </v-col>

                    <v-col cols="8" class="text-right">
                        <v-btn
                            class="text-none"
                            color="green"
                            @click="$refs.createDialog.open()"
                            depressed
                            dark
                        >
                            <v-icon class="mr-2" dark>mdi-account-plus</v-icon>
                            Controleur toevoegen
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>

            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="inspectors"
                    :search="search"
                    :loading="loadingInspectors"
                    loading-text="Controleurs ophalen..."
                >
                    <!-- Password Column -->
                    <template v-slot:[`item.password`]="{ item }">
                        <input
                            :value="item.password"
                            :type="showPassword ? 'text' : 'password'"
                            :style="
                                'width:' + (item.password.length - 1) + 'ch'
                            "
                            disabled
                        />

                        <v-icon @click="showPassword = !showPassword">
                            {{ showPassword ? "mdi-eye-off" : "mdi-eye" }}
                        </v-icon>
                    </template>

                    <!-- Action Column -->
                    <template v-slot:[`item.action`]="{ item }">
                        <v-btn icon @click="openDeleteConfirmation(item.id)">
                            <v-icon color="red" small> mdi-delete </v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <create-dialog ref="createDialog" @inspector="addInspector" />
        <delete-confirmation
            ref="deleteDialog"
            title="Bent u zeker?"
            text="Bent u zeker dat u deze controleur wilt verwijderen?"
            @confirm="deleteInspector"
        />
    </div>
</template>

<script>
import CreateDialog from "@/components/inspectors/CreateDialog.vue";
import DeleteConfirmation from "@/components/DeleteConfirmation.vue";

export default {
    components: {
        CreateDialog,
        DeleteConfirmation,
    },
    data() {
        return {
            search: null,
            loadingInspectors: true,
            inspectors: [],
            headers: [
                { text: "Gebruikersnaam", value: "username" },
                { text: "Wachtwoord", value: "password" },
                { text: "Memo", value: "memo" },
                { text: "Acties", value: "action" },
            ],
            showPassword: false,
        };
    },
    methods: {
        openDeleteConfirmation(id) {
            this.$refs.deleteDialog.setId(id);
            this.$refs.deleteDialog.open();
        },
        addInspector(newInspector) {
            this.inspectors.push(newInspector);
        },
        deleteInspector(id) {
            this.$store.dispatch("inspector/delete", id).finally(() => {
                this.inspectors.forEach(function (
                    inspector,
                    index,
                    inspectors
                ) {
                    if (inspector.id == id) inspectors.splice(index, 1);
                });

                this.$refs.deleteDialog.close();
            });
        },
    },
    mounted() {
        this.$store
            .dispatch("inspector/index")
            .then((inspectors) => {
                this.inspectors = inspectors;
            })
            .finally(() => {
                this.loadingInspectors = false;
            });
    },
};
</script>
