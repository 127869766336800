<template>
    <v-dialog v-model="dialog" width="320" persistent>
        <v-card>
            <v-btn
                class="dialog__close"
                icon
                x-small
                @click.native="dialog = false"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-card-text class="text-center pa-4">
                <div id="badge-status" v-if="status">
                    <v-icon
                        :color="badgeStatusColor"
                        class="badge-status-icon"
                        width="120"
                    >
                        {{ badgeStatusIcon }}
                    </v-icon>

                    <h3>{{ badgeStatusText }}</h3>
                </div>

                <div v-else>
                    <v-progress-circular
                        class="mt-5 mb-3"
                        indeterminate
                        size="64"
                    ></v-progress-circular>
                </div>

                <v-btn class="mt-5" color="primary" block @click="close"
                    >Opnieuw scannen</v-btn
                >
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "StatusDialog",
    data() {
        return {
            dialog: false,
            status: null,
        };
    },
    computed: {
        badgeStatusColor() {
            if (this.status === "invalid") return "error";
            else if (this.status === "valid") return "success";
            else return "warning";
        },
        badgeStatusIcon() {
            if (this.status === "invalid") return "mdi-minus-circle-outline";
            else if (this.status === "valid") return "mdi-check-circle-outline";
            else return "mdi-alert-circle-outline";
        },
        badgeStatusText() {
            if (this.status === "invalid") return "GEEN GELDIGE QR";
            else if (this.status === "valid") return "GEACTIVEERD";
            else return "REEDS GEACTIVEERD";
        },
    },
    methods: {
        validate(id) {
            // workerId QR always starts with KTNPLANNER
            if (!id.startsWith("KTNPLANNER")) {
                this.status = "invalid";
            } else {
                id = id.replace("KTNPLANNER", "");

                this.$store.dispatch("badge/activate", id).then((resp) => {
                    this.status = resp.status;
                });
            }

            this.dialog = true;
        },
        close() {
            // Slight delay on status null
            setTimeout(() => {
                this.status = null;
            }, 200);

            this.$emit("close");

            this.dialog = false;
        },
    },
};
</script>

<style scoped>
.v-icon.badge-status-icon {
    font-size: 150px;
    margin-top: 10px;
    margin-bottom: 10px;
}
</style>