import Vue from "vue";
import Vuex from "vuex";

import axios from "axios";

Vue.use(Vuex);

const badgeModule = {
	namespaced: true,
	actions: {
		async activate(context, id) {
			await axios.get("/sanctum/csrf-cookie");

			return new Promise((resolve, reject) => {
				axios.post("/api/badge/activate", { id: id }).then((resp) => {
					resolve(resp.data);
				}).catch((error) => {
					reject(error.response);
				});
			});
		},
		generate(context, data) {
			return new Promise((resolve, reject) => {
				axios.post("/api/badge/generate", data, {
					headers: {
						"Content-Type": "multipart/form-data"
					},
					responseType: "blob" // important!
				}).then((resp) => {
					resolve(resp.data);
				}).catch((error) => {
					reject(error.response);
				});
			});
		},
		// Generate Excel list for smstools group sms
		list(context, data) {
			return new Promise((resolve, reject) => {
				axios.post("/api/badge/list", data, {
					headers: {
						"Content-Type": "multipart/form-data"
					},
					responseType: "blob" // important!
				}).then((resp) => {
					resolve(resp.data);
				}).catch((error) => {
					reject(error.response);
				});
			});
		},
		// Downloads badge in sticker format
		download(context, id) {
			return new Promise((resolve, reject) => {
				axios.get(`/api/badge/${id}`, {
					responseType: "blob" // important!
				}).then((resp) => {
					resolve(resp.data);
				}).catch((error) => {
					reject(error.response);
				});
			});
		},
		// Gets worker information to create digital badge
		digital(context, hash) {
			return new Promise((resolve) => {
				axios.get(`/api/badge/hash/${hash}`).then((resp) => {
					resolve(resp.data);
				});
			});
		},
		send(context, id) {
			return new Promise((resolve, reject) => {
				axios.post(`/api/badge/${id}/send`).then((resp) => {
					resolve(resp.data);
				}).catch((error) => {
					reject(error.response);
				});
			});
		}
	},
};

export default badgeModule;
