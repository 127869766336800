<template>
    <v-dialog v-model="dialog" max-width="800">
        <v-card>
            <v-btn
                class="dialog__close"
                icon
                x-small
                @click.native="dialog = false"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-card-title>Operation verplaatsen</v-card-title>

            <v-card-text class="pb-2 pt-2">
                <v-row>
                    <v-col cols="7">
                        <v-row>
                            <v-col cols="6">
                                <v-select
                                    v-model="selectedSourceBus"
                                    label="Van"
                                    :items="buses"
                                    item-value="id"
                                    :item-text="
                                        (item) => 'Bus ' + item.bus_number
                                    "
                                    required
                                    hide-details
                                    @change="
                                        selectedSourceOperation =
                                            selectedTargetBus = null
                                    "
                                />
                            </v-col>

                            <v-col cols="6">
                                <v-select
                                    v-model="selectedSourceOperation"
                                    label="Operation"
                                    :items="operations"
                                    item-text="operation"
                                    :disabled="selectedSourceBus == null"
                                    @change="selectedTargetBus = null"
                                    required
                                    hide-details
                                    return-object
                                />
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col cols="1" class="d-flex align-center justify-center">
                        <v-icon large>mdi-arrow-right</v-icon>
                    </v-col>

                    <v-col cols="4">
                        <v-select
                            v-model="selectedTargetBus"
                            label="Naar"
                            :items="buses"
                            :item-value="(item) => item.id"
                            :item-text="(item) => 'Bus ' + item.bus_number"
                            required
                            hide-details
                        />

                        <p v-if="limitError" class="red--text ma-0 mt-2">
                            Operation past niet in bus
                        </p>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions>
                <v-btn text @click="dialog = false" :disabled="loading">
                    Annuleren
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn
                    color="success"
                    text
                    :disabled="invalidMove"
                    :loading="loading"
                    @click="moveOperation"
                >
                    Verplaatsen
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { EventBus } from "@/event-bus.js";

export default {
    name: "MoveOperationDialog",
    props: {
        type: String,
        shift: Object,
        date: String,
    },
    data() {
        return {
            dialog: false,

            buses: [],
            operations: [],
            selectedSourceBus: null,
            selectedTargetBus: null,
            selectedSourceOperation: null,

            limitError: false,
            loading: false,
        };
    },
    computed: {
        invalidMove() {
            if (this.limitError) return true;

            if (
                this.selectedSourceBus == null ||
                this.selectedSourceOperation == null ||
                this.selectedTargetBus == null
            )
                return true;

            return false;
        },
    },
    mounted() {
        this.buses.push(...this.shift.buses_all);
        this.buses.push(...this.shift.buses_left);
        this.buses.push(...this.shift.buses_right);

        EventBus.$on("move-operation-" + this.type, ({ bus, operation }) => {
            this.selectedSourceBus = bus.id;
            this.selectedSourceOperation = operation;

            this.dialog = true;
        });
    },
    methods: {
        moveOperation() {
            this.loading = true;

            let data = {
                shift_date: this.date,
                source_bus: this.selectedSourceBus,
                target_bus: this.selectedTargetBus,
                operation: this.selectedSourceOperation.operation,
            };

            this.$store
                .dispatch("planning/moveOperation", data)
                .then(() => {
                    EventBus.$emit("reload-bus-grouping-" + this.type);
                })
                .finally(() => {
                    this.dialog = false;
                });
        },
    },
    watch: {
        dialog() {
            if (this.dialog) return;

            // Reset dialog
            this.selectedSourceBus = null;
            this.selectedTargetBus = null;
            this.selectedSourceOperation = null;

            this.loading = false;
            this.limitError = false;
        },
        selectedSourceBus() {
            this.operations = [];

            for (let i = 0; i < this.buses.length; ++i) {
                if (this.buses[i].id != this.selectedSourceBus) continue;

                for (const [operation, amount] of Object.entries(
                    this.buses[i].operations
                )) {
                    this.operations.push({
                        operation: operation,
                        amount: amount,
                    });
                }
            }
        },
        selectedTargetBus() {
            // Check if operation fits in bus
            this.limitError = false;

            if (this.selectedSourceOperation == null) return;

            let operationAmount = this.selectedSourceOperation.amount;

            for (let i = 0; i < this.buses.length; ++i) {
                if (this.buses[i].id == this.selectedTargetBus) {
                    let currTargetAmount = this.buses[i].total;

                    this.limitError =
                        operationAmount + currTargetAmount > this.$busCapacity;

                    return;
                }
            }
        },
    },
};
</script>