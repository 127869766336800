<template>
    <v-container class="fill-height">
        <v-row>
            <v-col cols="12" align="center">
                <v-card class="pa-10 pt-6 pb-6" width="400">
                    <v-img
                        :src="require(`@/assets/logo.jpg`)"
                        class="mb-4 mx-auto"
                        width="100"
                    />

                    <form
                        id="forgot-password"
                        v-on:submit.prevent="requestPasswordReset"
                    >
                        <v-text-field
                            v-model="email"
                            label="E-mail"
                            type="email"
                            required
                            hide-details
                        ></v-text-field>

                        <!-- Message Success -->
                        <p
                            v-if="messageSuccess"
                            class="text-left green--text ma-0 mt-1"
                        >
                            <small>{{ messageSuccess }}</small>
                        </p>

                        <!-- Message Error -->
                        <p
                            v-if="messageError"
                            class="text-left red--text ma-0 mt-1"
                        >
                            <small>{{ messageError }}</small>
                        </p>

                        <v-btn
                            type="submit"
                            class="white--text text-none mt-8"
                            color="primary"
                            large
                            block
                            depressed
                            :loading="requesting"
                        >
                            Reset aanvragen
                        </v-btn>

                        <!-- Back to login -->
                        <p class="mt-1 text-left" style="font-size: 15px">
                            <router-link to="login">
                                Terug naar aanmelden
                            </router-link>
                        </p>
                    </form>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "ForgotPassword",
    data() {
        return {
            email: null,
            messageSuccess: null,
            messageError: null,
            requesting: false,
        };
    },
    methods: {
        requestPasswordReset() {
            this.messageSuccess = null;
            this.messageError = null;

            this.requesting = true;

            this.$store
                .dispatch("forgotPassword", {
                    email: this.email,
                })
                .then((resp) => {
                    this.messageSuccess = resp.message;
                })
                .catch((error) => {
                    this.messageError = error.errors.email[0];
                })
                .finally(() => {
                    this.requesting = false;
                });

            return false;
        },
    },
};
</script>
