<template>
    <v-card class="mb-4" elevation="1">
        <v-card-title>
            <label>
                {{ headerTitle }}

                <!-- Status -->
                <v-tooltip v-if="!busGroupingLoading" bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            :color="shiftStatus"
                            v-bind="attrs"
                            v-on="on"
                            style="margin-bottom: 1px"
                        >
                            mdi-circle
                        </v-icon>
                    </template>
                    <span>{{ shiftText }}</span>
                </v-tooltip>
            </label>

            <v-spacer />

            <div id="planning-header">
                <v-btn
                    class="mr-2 text-none"
                    color="primary"
                    depressed
                    @click="$refs.importListDialog.open()"
                    :disabled="
                        busGroupingLoading ||
                        (busGrouping && busGrouping.confirmed)
                    "
                >
                    <v-icon class="mr-2" dark small>mdi-file-upload</v-icon>
                    Opladen
                </v-btn>

                <v-btn
                    class="mr-2 text-none"
                    color="success"
                    depressed
                    @click="$refs.confirmDialog.open()"
                    :disabled="
                        busGroupingLoading ||
                        busGrouping == null ||
                        busGrouping.confirmed
                    "
                >
                    <v-icon class="mr-2" dark small>mdi-check-bold</v-icon>
                    Bevestig
                </v-btn>
            </div>
        </v-card-title>

        <v-card-text>
            <!-- Shift information loading -->
            <div v-if="busGroupingLoading" class="text-center mt-2">
                <v-progress-circular
                    indeterminate
                    size="42"
                ></v-progress-circular>
            </div>

            <!-- Warning if no upload yet -->
            <v-alert
                v-else-if="busGrouping == null"
                class="mb-2"
                type="warning"
                text
                dense
            >
                Er is nog geen lijst opgeladen. Gelieve de Ortec lijst op te
                laden om de bus indeling te genereren.
            </v-alert>

            <v-expansion-panels v-else accordion v-model="panel">
                <v-expansion-panel
                    v-for="(shift, i) in busGrouping.shifts"
                    :key="i"
                >
                    <v-expansion-panel-header>
                        <strong>{{ shift.shift_time }}</strong>

                        <v-btn
                            class="mr-4 text-none"
                            color="primary"
                            @click.native.stop="
                                exportBusGrouping(shift.shift_start)
                            "
                            :disabled="
                                busGroupingLoading || busGrouping == null
                            "
                            :loading="busGroupingExporting"
                            depressed
                            small
                            max-width="150"
                        >
                            <v-icon class="mr-2" dark small>
                                mdi-download
                            </v-icon>
                            Exporteren
                        </v-btn>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                        <v-row>
                            <v-col
                                v-if="shift.buses_left.length > 0"
                                cols="12"
                                lg="6"
                            >
                                <bus-grouping-detailed
                                    v-for="bus in shift.buses_left"
                                    :key="bus.id"
                                    :type="type"
                                    :bus="bus"
                                    :confirmed="busGrouping.confirmed"
                                    class="mb-4"
                                ></bus-grouping-detailed>
                            </v-col>

                            <v-col
                                v-if="shift.buses_right.length > 0"
                                cols="12"
                                lg="6"
                            >
                                <bus-grouping-detailed
                                    v-for="bus in shift.buses_right"
                                    :key="bus.id"
                                    :type="type"
                                    :bus="bus"
                                    :confirmed="busGrouping.confirmed"
                                    class="mb-4"
                                ></bus-grouping-detailed>
                            </v-col>

                            <v-col v-if="shift.buses_all.length > 0" cols="12">
                                <bus-grouping-detailed
                                    v-for="bus in shift.buses_all"
                                    :key="bus.id"
                                    :type="type"
                                    :bus="bus"
                                    :confirmed="busGrouping.confirmed"
                                    class="mb-4"
                                ></bus-grouping-detailed>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>

                    <move-operation-dialog
                        ref="moveOperationDialog"
                        :type="type"
                        :shift="shift"
                        :date="date"
                    />
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card-text>

        <confirm-dialog
            ref="confirmDialog"
            :grouping="busGrouping"
            @confirm="confirm"
        />

        <import-list-dialog ref="importListDialog" :type="type" :date="date" />
    </v-card>
</template>

<script>
import { EventBus } from "@/event-bus.js";

import BusGroupingDetailed from "@/components/planning/BusGroupingDetailed.vue";
import ImportListDialog from "@/components/planning/ImportListDialog.vue";
import ConfirmDialog from "@/components/planning/ConfirmDialog.vue";
import MoveOperationDialog from "@/components/planning/MoveOperationDialog.vue";

export default {
    props: {
        date: String,
        type: String,
    },
    components: {
        ImportListDialog,
        BusGroupingDetailed,
        ConfirmDialog,
        MoveOperationDialog,
    },
    data() {
        return {
            panel: null,
            busGrouping: null,
            busGroupingLoading: false,
            busGroupingExporting: false,
            headers: [
                {
                    text: "Operation",
                    value: "operations",
                },
                {
                    text: "Totaal",
                    value: "total",
                },
            ],
            stopInitialLoad: false,
        };
    },
    computed: {
        shiftStatus() {
            if (this.busGrouping == null) return "error";
            else if (this.busGrouping.confirmed) return "success";
            else return "warning";
        },
        shiftText() {
            if (this.busGrouping == null) return "Geen lijst opgeladen";
            else if (this.busGrouping.confirmed)
                return "Dienst bevestigd en eindpersonen gecontacteerd";
            else return "Dienst nog niet bevestigd";
        },
        headerTitle() {
            return this.type == "early"
                ? "Vroege dienst"
                : "Late & nachtdienst";
        },
    },
    mounted() {
        EventBus.$on("reload-bus-grouping-" + this.type, () => {
            this.getBusGrouping();
        });
    },
    methods: {
        exportBusGrouping(start) {
            this.busGroupingExporting = true;

            this.$store
                .dispatch("planning/exportBusGrouping", {
                    date: this.date,
                    start: start,
                })
                .then((blob) => {
                    let type = this.type == "early" ? "VROEG" : "LAAT";
                    var link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download =
                        "Buslijsten " + this.date + " " + type + ".xlsx";
                    link.click();
                    URL.revokeObjectURL(link.href);
                })
                .finally(() => {
                    this.busGroupingExporting = false;
                });
        },
        getBusGrouping() {
            this.busGrouping = null;
            this.busGroupingLoading = true;

            this.$store
                .dispatch("planning/busGrouping", {
                    type: this.type,
                    date: this.date,
                })
                .then((resp) => {
                    this.busGrouping = resp;
                })
                .catch(() => {
                    this.busGrouping = null;
                })
                .finally(() => {
                    if (!this.stopInitialLoad) this.busGroupingLoading = false;
                    this.stopInitialLoad = false;
                });
        },
        confirm() {
            this.$store
                .dispatch("planning/confirmBusGrouping", {
                    type: this.type,
                    date: this.date,
                })
                .then(() => {
                    this.busGrouping.confirmed = true;
                })
                .finally(() => {
                    this.$refs.confirmDialog.close();
                });
        },
    },
    watch: {
        date: {
            handler: function () {
                // Check if already loading
                if (this.busGroupingLoading) this.stopInitialLoad = true;

                // Close all panels
                this.panel = null;

                this.getBusGrouping();
            },
            immediate: true,
        },
    },
};
</script>

<style>
</style>