<template>
    <v-dialog v-model="dialog" width="500" persistent>
        <v-card v-if="data">
            <v-card-title>Opgelet</v-card-title>

            <v-card-text class="pb-0">
                <v-alert class="mb-0" type="warning" text dense>
                    Busbestelling voor de
                    <strong>
                        shift van {{ data.shift_start }}h op
                        {{ data.shift_date }}
                    </strong>
                    komt niet langer overeen met huidige planning. <br /><br />

                    Huidige uitzendkrachten passen niet meer in bestelde bussen.
                    <strong>
                        Gelieve De Polder te contacteren voor
                        {{ data.amount }} extra bus(sen) {{ busCirculation }}.
                    </strong>
                    We zullen automatisch de extra bus(sen) inplannen in ons systeem.
                </v-alert>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                    type="submit"
                    color="success"
                    text
                    :loading="confirming"
                    @click="confirm"
                >
                    Bevestig
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            dialog: false,
            confirming: false,
            data: null,
        };
    },
    computed: {
        busCirculation() {
            if (this.data.circulation == "left")
                return "voor linker circulatiehelft";
            else if (this.data.circulation == "right")
                return "voor rechter circulatiehelft";

            return "";
        },
    },
    methods: {
        open(data) {
            this.data = data;
            this.dialog = true;
        },
        confirm() {
            this.confirming = true;

            // Order new bus
            this.$store
                .dispatch("planning/orderBus", this.data)
                .then(() => {
                    this.$emit("ordered");
                })
                .finally(() => {
                    this.confirming = false;
                    this.dialog = false;
                });
        },
    },
};
</script>

<style>
</style>