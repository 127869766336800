import Vue from "vue";
import Vuex from "vuex";

import axios from "axios";

Vue.use(Vuex);

const validationModule = {
	namespaced: true,
	state: {},
	mutations: {},
	actions: {
		index(context, date) {
			return new Promise((resolve, reject) => {
				axios.get("/api/validate?date=" + date).then((resp) => {
					resolve(resp.data);
				}).catch((error) => {
					reject(error);
				});
			});
		},
		exportLogs(context, date) {
			return new Promise((resolve, reject) => {
				axios.get("/api/validate/export?date=" + date, {
					responseType: 'blob'
				}).then((resp) => {
					resolve(resp.data);
				}).catch((error) => {
					reject(error);
				});
			});
		},
	},
	getters: {}
};

export default validationModule;
