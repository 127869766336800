<template>
    <v-dialog v-model="dialog" width="600" persistent>
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="text-none white--text mr-2"
                small
                depressed
                color="green"
                :disabled="disabled"
                v-bind="attrs"
                v-on="on"
            >
                Uitzendkracht toevoegen
            </v-btn>
        </template>

        <v-card>
            <v-btn
                class="dialog__close"
                icon
                x-small
                @click.native="dialog = false"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-card-title>Uitzendkracht toevoegen</v-card-title>

            <v-card-text>
                <form v-on:submit.prevent="searchWorker">
                    <v-text-field
                        v-model="search"
                        label="Zoeken"
                        type="text"
                        append-icon="mdi-magnify"
                        required
                        hide-details
                    ></v-text-field>

                    <v-btn
                        class="text-none mt-2 mr-2"
                        type="submit"
                        depressed
                        small
                        color="primary"
                        :loading="searching"
                    >
                        <v-icon class="mr-1" small>mdi-magnify</v-icon>
                        Zoeken
                    </v-btn>

                    <v-btn
                        class="text-none mt-2 white--text"
                        depressed
                        small
                        color="green"
                        :disabled="searching"
                        @click="$refs.createDialog.open()"
                    >
                        <v-icon class="mr-1" small>mdi-plus</v-icon>
                        Nieuw
                    </v-btn>
                </form>

                <p v-if="notFoundError" class="text-left red--text ma-0 mt-2">
                    Geen uitzendkracht gevonden.
                </p>

                <v-row v-else-if="workers" class="mt-0">
                    <v-col cols="12">
                        <v-select
                            v-model="planning.worker_id"
                            label="Uitzendkracht*"
                            :items="workers"
                            class="mt-4 mb-2"
                            required
                            hide-details
                        />
                    </v-col>
                </v-row>

                <!-- Planning information -->
                <v-row v-if="planning.worker_id" class="mt-0">
                    <v-col cols="6">
                        <v-select
                            v-model="planning.operation"
                            :items="operations"
                            label="Operation*"
                            required
                            hide-details
                        ></v-select>
                    </v-col>

                    <v-col cols="6">
                        <v-text-field
                            v-model="planning.activity"
                            label="Activity*"
                            type="text"
                            required
                            hide-details
                        ></v-text-field>
                    </v-col>

                    <v-col cols="6">
                        <v-text-field
                            v-model="planning.category"
                            label="Categorie (optioneel)"
                            type="text"
                            class="mb-2"
                            hide-details
                        ></v-text-field>
                    </v-col>

                    <v-col cols="6">
                        <v-text-field
                            v-model="planning.location"
                            label="Opstaplocatie (optioneel)"
                            type="text"
                            class="mb-2"
                            hide-details
                        ></v-text-field>
                    </v-col>

                    <v-col v-if="confirmed" cols="12" class="pt-0 pb-0">
                        <v-checkbox
                            v-model="planning.notify"
                            class="ma-0 pa-0"
                            label="Uitzendkracht contacteren"
                            hide-details
                            dense
                        ></v-checkbox>
                    </v-col>
                </v-row>

                <p
                    v-if="alreadyExistsError"
                    class="text-left red--text ma-0 mt-2"
                >
                    Deze uitzendkracht zit reeds in shift.
                </p>
            </v-card-text>

            <v-card-actions>
                <v-btn text @click="dialog = false" :disabled="searching">
                    Annuleren
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn
                    color="success"
                    text
                    @click="addWorker"
                    :loading="adding"
                    :disabled="disableConfirm"
                >
                    Bevestig
                </v-btn>
            </v-card-actions>
        </v-card>

        <create-dialog ref="createDialog" />
    </v-dialog>
</template>

<script>
import { EventBus } from "@/event-bus.js";

import CreateDialog from "@/components/workers/CreateDialog.vue";

export default {
    props: {
        type: String,
        bus: Number,
        disabled: Boolean,
        confirmed: Boolean,
    },
    components: { CreateDialog },
    data() {
        return {
            dialog: false,

            search: null,
            searching: false,
            workers: null,
            adding: false,
            notFoundError: false,
            alreadyExistsError: false,

            planning: {
                bus_id: null,
                worker_id: null,
                operation: null,
                activity: null,
                category: null,
                location: null,
                notify: false,
            },

            operations: [
                "BR-Inbound",
                "BR-Infeed",
                "BR-Outbound",
                "BR-Picking",
                "BR-Returns",
                "BR-Stock",
                "BR-Technical Dep.",
                "BR-TMS",
                "BR-WH17",
                "Talent Pool",
                "Groupe Adeo",
                "Groupe Adeo OH",
                "Polaris",
                "Beliving",
                "X2O",
                "KitchenAid",
                "Intergamma",
                "Telenet",
                "Initial",
                "SkatePro",
                "Spyder",
                "Zeeman",
                "Made Furniture",
                "Made Carpets",
                "Made Home",
                "Made.Com",
                "De Longhi",
                "Zooplus",
                "Gildan",
                "Beechfield",
                "Adan&Anais",
                "Livingstone",
                "Halyard Health",
                "Baxter",
                "Zoetis",
                "Avanos",
                "Outspot",
                "Stanley & Stella",
                "Velg",
                "Vente Exclusive",
                "Nilfisk",
                "Haier",
                "Dole",
                "CAC",
                "LCG",
                "Le Creuset",
				"Terra Incognita"
            ],
        };
    },
    computed: {
        disableConfirm() {
            return (
                this.planning.bus_id == null ||
                this.planning.worker_id == null ||
                this.planning.operation == null ||
                this.planning.activity == null
            );
        },
    },
    mounted() {
        this.planning.bus_id = this.bus;
    },
    methods: {
        searchWorker() {
            this.searching = true;
            this.notFoundError = false;

            this.workers = null;
            this.clearPlanning();

            this.$store
                .dispatch("worker/index", this.search)
                .then((workers) => {
                    if (workers.length == 0) {
                        this.notFoundError = true;
                    } else {
                        this.workers = this.parseWorkers(workers);
                    }
                })
                .finally(() => {
                    this.searching = false;
                });
        },
        addWorker() {
            this.adding = true;
            this.alreadyExistsError = false;

            this.$store
                .dispatch("planning/addWorker", this.planning)
                .then(() => {
                    EventBus.$emit("reload-bus-grouping-" + this.type);

                    this.dialog = false;
                    this.clearPlanning();
                })
                .catch((error) => {
                    if (error.status == 409) this.alreadyExistsError = true;
                })
                .finally(() => {
                    this.adding = false;
                });
        },
        parseWorkers(workers) {
            let tmpWorkers = [];

            for (var i = 0; i < workers.length; ++i) {
                let worker = workers[i];

                tmpWorkers.push({
                    text:
                        worker.first_name +
                        " " +
                        worker.last_name +
                        " - " +
                        worker.id +
                        " - " +
                        worker.agency,
                    value: worker.id,
                });
            }

            return tmpWorkers;
        },
        clearPlanning() {
            this.planning.worker_id = null;
            this.planning.operation = null;
            this.planning.activity = null;
            this.planning.category = null;
            this.planning.location = null;
            this.planning.notify = false;
        },
    },
};
</script>