<template>
    <div id="statistics">
        <h2 class="mb-2">Statistieken</h2>

        <v-card elevation="1">
            <v-card-text>
                <div class="text-center chart-header">
                    Busgebruikers per partner
                </div>

                <v-row class="mt-0">
                    <!--
                    <v-col cols="3" class="ml-4">
                        <v-select
                            v-model="interval"
                            label="Interval"
                            :items="intervalOptions"
                            dense
                        />
                    </v-col>
					-->
                    <v-spacer />

                    <v-col cols="4" class="ml-4">
                        <v-select
                            v-model="range"
                            label="Range"
                            :items="rangeOptions"
                            dense
                        />
                    </v-col>
                </v-row>

                <highcharts
                    v-if="render"
                    ref="highcharts"
                    :options="options"
                ></highcharts>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import axios from "axios";

import Highcharts from "highcharts";

const monthNames = [
    "Januari",
    "Februari",
    "Maart",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Augustus",
    "September",
    "Oktober",
    "November",
    "December",
];

const template = {
    chart: {
        type: "column",
        events: {
            load() {
                this.showLoading();
            },
        },
    },
    title: {
        text: null,
    },
    xAxis: {
        categories: monthNames,
    },
    yAxis: {
        min: 0,
        title: {
            text: "Aantal",
        },
        labels: {
            overflow: "justify",
        },
    },
    plotOptions: {
        bar: {
            dataLabels: {
                enabled: true,
            },
        },
    },
    legend: {
        layout: "vertical",
        align: "right",
        verticalAlign: "top",
        floating: true,
        borderWidth: 1,
        backgroundColor:
            (Highcharts.theme && Highcharts.theme.legendBackgroundColor) ||
            "#FFFFFF",
        shadow: true,
    },
    credits: {
        enabled: false,
    },
    series: [
        {
            name: "Synergie",
            data: [],
            color: "#e2001a",
        },
        {
            name: "Konvert",
            data: [],
            color: "#00cd00",
        },
        {
            name: "AGO Jobs",
            data: [],
            color: "#00a3e0",
        },
        {
            name: "Deltaworx",
            data: [],
            color: "#F9A03F",
        },
    ],
};

export default {
    name: "Statistics",
    data() {
        return {
            render: false,

            range: "current",

            interval: "month",
            intervalOptions: [
                {
                    text: "Week",
                    value: "week",
                },
                {
                    text: "Maand",
                    value: "month",
                },
            ],

            options: template,
        };
    },
    components: {
        highcharts: Chart,
    },
    computed: {
        rangeOptions() {
            if (this.interval == "week") {
                let range = [
                    { text: "Deze maand", value: "current" },
                    { text: "Vorige maand", value: "previous" },
                    { divider: true },
                ];

                let d1 = new Date("2021-06-01"); // Start project
                let d2 = new Date();

                let amount = this.monthDiff(d1, d2);
                amount--; // Remove last month (use "Vorige maand" instead)

                let months = [];
                for (let i = 0; i < amount; ++i) {
                    var current =
                        d1.getMonth() == 11
                            ? new Date(d1.getFullYear() + 1, 0, 1)
                            : new Date(d1.getFullYear(), d1.getMonth() + i, 1);

                    var monthName = monthNames[current.getMonth()];
                    var year = current.getFullYear();

                    months.unshift({
                        text: monthName + " " + year,
                        value: current.getFullYear() + "-" + current.getMonth(),
                    });
                }

                range.push(...months);

                return range;
            } else if (this.interval == "month") {
                let now = new Date();

                let range = [
                    { text: now.getFullYear(), value: "current" },
                    { text: now.getFullYear() - 1, value: "previous" },
                ];

                return range;
            }

            return [];
        },
    },
    mounted() {
        if (!this.$store.getters.isAdmin)
            this.$router.push({ name: "Planning" });

        // Small delay on render in order to fit chart inside card
        setTimeout(() => {
            this.render = true;

            this.updateStatistics();
        }, 200);
    },
    methods: {
        monthDiff(d1, d2) {
            var months;
            months = (d2.getFullYear() - d1.getFullYear()) * 12;
            months -= d1.getMonth();
            months += d2.getMonth();

            return months <= 0 ? 0 : months;
        },
        updateStatistics() {
            let highcharts = this.$refs.highcharts;

            if (highcharts) highcharts.chart.showLoading();

            axios
                .get(
                    `/api/statistics?interval=${this.interval}&range=${this.range}`
                )
                .then((resp) => {
                    let chart = this.$refs.highcharts.chart;

                    chart.series[0].setData(resp.data["synergie"], true);
                    chart.series[1].setData(resp.data["konvert"], true);
                    chart.series[2].setData(resp.data["ago"], true);
                    chart.series[3].setData(resp.data["deltaworx"], true);

                    chart.hideLoading();
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    watch: {
        range: {
            handler() {
                this.updateStatistics();
            },
        },
        interval: {
            handler() {
                // Force trigger range watch
                if (this.range === "current") this.updateStatistics();
                else this.range = "current";
            },
        },
    },
};
</script>

<style scoped>
.chart-header {
    color: #333333;
    font-size: 20px;
    fill: #333333;
    margin-bottom: 6px;
}
</style>

