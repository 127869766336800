<template>
    <v-dialog v-model="dialog" width="420">
        <template v-slot:activator="{ on, attrs }">
            <v-icon class="mr-2" small v-bind="attrs" v-on="on">
                mdi-qrcode
            </v-icon>
        </template>
        <v-card>
            <v-btn
                class="dialog__close"
                icon
                x-small
                @click.native="dialog = false"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-card-text class="pt-8 pb-6">
                <badge class="mb-4" :worker="worker" ref="badge" />

                <div id="badge-actions">
                    <v-btn
                        class="mr-2 mb-1 text-none"
                        color="primary"
                        depressed
                        small
                        @click="$refs.badge.print()"
                        title="Download Badge"
                    >
                        <v-icon class="mr-1" small>mdi-download</v-icon>
                        Badge (digitaal)
                    </v-btn>

                    <v-btn
                        class="mr-2 mb-1 text-none"
                        color="primary"
                        depressed
                        small
                        :loading="badgeLoading"
                        @click="downloadBadge"
                        title="Download Badge"
                    >
                        <v-icon class="mr-1" small>mdi-download</v-icon>
                        Badge (sticker)
                    </v-btn>

                    <v-btn
                        class="mr-2 text-none"
                        color="primary"
                        depressed
                        small
                        title="Kopieer link"
                        :href="`https://app.ktnbusplanner.be/badge/${worker.md5_hash}`"
                        target="_blank"
                    >
                        <v-icon class="mr-1" small>mdi-link-variant</v-icon>
                        Open link
                    </v-btn>

                    <v-btn
                        class="mr-2 text-none"
                        color="primary"
                        depressed
                        small
                        :loading="smsLoading"
                        @click="sendMessage"
                        title="Verstuur SMS"
                    >
                        <v-icon class="mr-1" small>mdi-message-fast</v-icon>
                        Verstuur SMS
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>

        <!-- Success notification -->
        <v-snackbar
            v-model="snackbarSuccess"
            color="success"
            :timeout="5000"
            top
            right
        >
            <v-icon class="mr-2">mdi-check-circle</v-icon>

            <label>SMS verstuurd naar uitzendkracht</label>
        </v-snackbar>

        <!-- Error notification -->
        <v-snackbar
            v-model="snackbarError"
            color="error"
            :timeout="5000"
            top
            right
        >
            <v-icon class="mr-2">mdi-alert-circle</v-icon>

            <label>Fout bij het versturen van SMS</label>
        </v-snackbar>
    </v-dialog>
</template>

<script>
import Badge from "./Badge.vue";

export default {
    props: {
        worker: Object,
    },
    components: {
        Badge,
    },
    data() {
        return {
            dialog: false,

            badgeLoading: false,
            smsLoading: false,

            snackbarSuccess: false,
            snackbarError: false,
        };
    },
    methods: {
        downloadBadge() {
            this.badgeLoading = true;

            this.$store
                .dispatch("badge/download", this.worker.id)
                .then((data) => {
                    var fileURL = window.URL.createObjectURL(new Blob([data]));
                    var fileLink = document.createElement("a");

                    fileLink.href = fileURL;
                    fileLink.setAttribute(
                        "download",
                        `badge_${this.worker.id}.pdf`
                    );
                    document.body.appendChild(fileLink);

                    fileLink.click();
                })
                .finally(() => {
                    this.badgeLoading = false;
                });
        },
        sendMessage() {
            this.smsLoading = true;

            this.$store
                .dispatch("badge/send", this.worker.id)
                .then(() => (this.snackbarSuccess = true))
                .catch(() => (this.snackbarError = true))
                .finally(() => {
                    this.smsLoading = false;
                });
        },
    },
};
</script>