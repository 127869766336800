<template>
    <div id="planning">
        <div id="planning-header">
            <h2 class="mb-2 mr-1 d-inline">{{ parsedDate }}</h2>

            <!-- Calendar -->
            <v-menu class="d-inline" absolute offset-y style="max-width: 600px">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mb-2" icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-calendar</v-icon>
                    </v-btn>
                </template>

                <v-date-picker
                    v-model="calendarDate"
                    @input="setDateFromCalendar()"
                ></v-date-picker>
            </v-menu>
        </div>

        <div v-if="calendarDate" id="information">
            <!-- Bus order -->
            <bus-order :date="calendarDate" />

            <!-- Early shift (6, 7, 8, 10) -->
            <bus-grouping :date="calendarDate" type="early" />

            <!-- Late shift (14, 22) -->
            <bus-grouping :date="calendarDate" type="late" />
        </div>
    </div>
</template>

<script>
import BusOrder from "@/components/planning/BusOrder.vue";
import BusGrouping from "@/components/planning/BusGrouping.vue";

export default {
    components: {
        BusOrder,
        BusGrouping,
    },
    data() {
        return {
            currentDate: new Date(),
            calendarDate: null,
        };
    },
    computed: {
        parsedDate() {
            const options = {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
            };

            let localeDate = this.currentDate.toLocaleDateString(
                "nl-BE",
                options
            );

            let [first, ...rest] = localeDate.split(" ");
            rest = rest.join(" ");

            localeDate =
                first.charAt(0).toUpperCase() + first.slice(1) + ", " + rest;

            return localeDate;
        },
    },
    mounted() {
        this.calendarDate = this.currentDate.toISOString().split("T")[0];
    },
    methods: {
        setDateFromCalendar() {
            this.currentDate.setTime(new Date(this.calendarDate).getTime());

            // Force recompute (parsedDate)
            this.currentDate = new Date(this.currentDate.getTime());

            this.calendarDate = this.currentDate.toISOString().split("T")[0];
        },
    },
};
</script>

<style></style>
