<template>
    <v-card id="badge" class="black--text pa-6 pb-2">
        <v-row ref="printMe">
            <v-col cols="5" class="text-center">
                <v-img
                    class="mb-2"
                    :src="`https://qrscan.codes/?url=KTNPLANNER${worker.id}`"
                    crossorigin="anonymous"
                    @load="qrLoaded"
                />

                <strong style="font-size: 17px">{{ worker.id }}</strong>
            </v-col>

            <v-col cols="7" class="d-flex flex-column">
                <strong style="font-size: 18px">
                    {{ worker.last_name + ", " + worker.first_name }}
                </strong>
                <div style="margin-top: 5px; font-size: 18px">
                    {{
                        worker.agency.charAt(0).toUpperCase() +
                        worker.agency.slice(1)
                    }}
                </div>
                <div style="margin-top: 5px; font-size: 16px">
                    {{ worker.badge_operation }}
                </div>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
export default {
    name: "Badge",
    props: {
        worker: Object,
        download: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        qrLoaded() {
            if (!this.download) return;

            setTimeout(() => {
                this.print();
            }, 200);
        },
        async print() {
            const el = this.$refs.printMe;
            // add option npmtype to get the image version
            // if not provided the promise will return
            // the canvas.
            const options = {
                type: "dataURL",
                useCORS: true,
            };

            let image = await this.$html2canvas(el, options);

            var a = document.createElement("a");
            a.href = image;
            a.download = "KTNPLANNER" + this.worker.id + ".png";
            a.click();
        },
    },
};
</script>

<style scoped>
#badge {
    width: 380px;
}
</style>