<template>
    <v-card class="mb-4" elevation="1">
        <v-card-title>
            <label>
                Bestelling

                <!-- Status -->
                <v-tooltip v-if="!busOrderLoading" bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            small
                            :color="orderStatus"
                            v-bind="attrs"
                            v-on="on"
                            style="margin-bottom: 1px"
                        >
                            mdi-circle
                        </v-icon>
                    </template>
                    <span>{{ orderText }}</span>
                </v-tooltip>
            </label>

            <v-spacer />

            <div id="planning-header">
                <v-btn
                    class="mr-2 text-none"
                    color="primary"
                    depressed
                    @click="$refs.importListDialog.open()"
                    :disabled="
                        busOrderLoading || (busOrder && busOrder.confirmed)
                    "
                >
                    <v-icon class="mr-2" dark small>mdi-file-upload</v-icon>
                    Opladen
                </v-btn>

                <v-btn
                    class="mr-2 text-none"
                    color="primary"
                    depressed
                    @click="exportBusOrder()"
                    :disabled="busOrderLoading || busOrder == null"
                    :loading="busOrderExporting"
                >
                    <v-icon class="mr-2" dark small>mdi-download</v-icon>
                    Exporteren
                </v-btn>

                <v-btn
                    class="mr-2 text-none"
                    color="success"
                    depressed
                    @click="$refs.confirmDialog.open()"
                    :disabled="
                        busOrderLoading ||
                        busOrder == null ||
                        busOrder.confirmed
                    "
                >
                    <v-icon class="mr-2" dark small>mdi-check-bold</v-icon>
                    Bevestig
                </v-btn>
            </div>
        </v-card-title>

        <v-card-text>
            <!-- Bus order loading -->
            <div v-if="busOrderLoading" class="text-center mt-2">
                <v-progress-circular
                    indeterminate
                    size="42"
                ></v-progress-circular>
            </div>

            <!-- Warning if no upload yet -->
            <v-alert
                v-else-if="busOrder == null"
                class="mb-2"
                type="warning"
                text
                dense
            >
                Er is nog geen lijst opgeladen. Gelieve de Ortec lijst op te
                laden om de bus bestelling te berekenen.
            </v-alert>

            <!-- Bus order information -->
            <div v-else id="bus-order">
                <div id="expansion-header" class="pl-5 pr-12 pb-2">
                    <v-row no-gutters>
                        <v-col cols="3">
                            <strong>Shift</strong>
                        </v-col>
                        <v-col cols="2">
                            <strong>Links (WH 40-56)</strong>
                        </v-col>
                        <v-col cols="3">
                            <strong>Rechts (WH 15-25)</strong>
                        </v-col>
                        <v-col cols="2">
                            <strong>Totaal</strong>
                        </v-col>
                    </v-row>
                </div>

                <v-expansion-panels accordion>
                    <v-expansion-panel
                        v-for="(order, i) in busOrder.order"
                        :key="i"
                    >
                        <v-expansion-panel-header>
                            <v-row no-gutters>
                                <v-col cols="3">
                                    <strong>{{ order.shift_time }}</strong>
                                </v-col>
                                <v-col cols="2">
                                    <strong>{{ order.left_amount }} </strong>
                                    <v-icon small color="black"
                                        >mdi-bus-multiple</v-icon
                                    >
                                </v-col>
                                <v-col cols="3">
                                    <strong>{{ order.right_amount }} </strong>
                                    <v-icon small color="black"
                                        >mdi-bus-multiple</v-icon
                                    >
                                </v-col>
                                <v-col cols="2">
                                    <strong>{{ order.total_amount }} </strong>
                                    <v-icon small color="black"
                                        >mdi-bus-multiple</v-icon
                                    >
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <bus-order-detailed :data="order.details" />
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>
        </v-card-text>

        <confirm-dialog ref="confirmDialog" @confirm="confirm" />
        <import-list-dialog ref="importListDialog" type="order" :date="date" />
    </v-card>
</template>

<script>
import { EventBus } from "@/event-bus.js";

import BusOrderDetailed from "@/components/planning/BusOrderDetailed.vue";
import ImportListDialog from "@/components/planning/ImportListDialog.vue";
import ConfirmDialog from "@/components/planning/ConfirmDialog.vue";

export default {
    props: {
        date: String,
    },
    components: {
        BusOrderDetailed,
        ImportListDialog,
        ConfirmDialog,
    },
    data() {
        return {
            busOrder: null,
            busOrderLoading: false,
            busOrderExporting: false,
            stopInitialLoad: false,
        };
    },
    computed: {
        orderStatus() {
            if (this.busOrder == null) return "error";
            else if (this.busOrder.confirmed) return "success";
            else return "warning";
        },
        orderText() {
            if (this.busOrder == null) return "Geen lijst opgeladen";
            else if (this.busOrder.confirmed) return "Verstuurd naar De Polder";
            else return "Niet verstuurd naar De Polder";
        },
    },
    mounted() {
        EventBus.$on("reload-bus-order", () => {
            this.getBusOrder();
        });
    },
    methods: {
        getBusOrder() {
            this.busOrder = null;
            this.busOrderLoading = true;

            this.$store
                .dispatch("planning/busOrder", this.date)
                .then((resp) => {
                    this.busOrder = resp;
                })
                .catch(() => {
                    this.busOrder = null;
                })
                .finally(() => {
                    if (!this.stopInitialLoad) this.busOrderLoading = false;
                    this.stopInitialLoad = false;
                });
        },
        exportBusOrder() {
            this.busOrderExporting = true;

            this.$store
                .dispatch("planning/exportBusOrder", this.date)
                .then((blob) => {
                    var link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = "bus-order-" + this.date + ".xlsx";
                    link.click();
                    URL.revokeObjectURL(link.href);
                })
                .finally(() => {
                    this.busOrderExporting = false;
                });
        },
        confirm() {
            this.$store
                .dispatch("planning/confirmBusOrder", this.date)
                .then(() => {
                    this.busOrder.confirmed = true;
                })
                .finally(() => {
                    this.$refs.confirmDialog.close();
                });
        },
        reload() {
            this.getBusOrder();
        },
    },
    watch: {
        date: {
            handler: function () {
                // Check if already loading
                if (this.busOrderLoading) this.stopInitialLoad = true;

                this.getBusOrder();
            },
            immediate: true,
        },
    },
};
</script>

<style></style>