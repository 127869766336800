import Vue from "vue";
import Vuex from "vuex";

import axios from "axios";

Vue.use(Vuex);

const inspectorModule = {
	namespaced: true,
	actions: {
		index() {
			return new Promise((resolve, reject) => {
				axios.get("/api/inspector").then((resp) => {
					resolve(resp.data);
				}).catch((error) => {
					reject(error);
				});
			});
		},
		create(context, inspector) {
			return new Promise((resolve, reject) => {
				axios.post("/api/inspector", inspector).then((resp) => {
					resolve(resp.data);
				}).catch((error) => {
					reject(error.response);
				});
			});
		},
		delete(context, id) {
			return new Promise((resolve, reject) => {
				axios.delete("/api/inspector/" + id).then(() => {
					resolve();
				}).catch((error) => {
					reject(error);
				});
			});
		}
	},
};

export default inspectorModule;
