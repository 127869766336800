<template>
    <v-dialog v-model="dialog" width="600">
        <v-card>
            <v-btn class="dialog__close" icon x-small @click.native="close()">
                <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-card-title> Uitzendkracht toevoegen </v-card-title>

            <form v-on:submit.prevent="create">
                <v-card-text class="pt-0">
                    <v-row>
                        <v-col cols="6">
                            <v-text-field
                                v-model="worker.id"
                                label="Werknemersnummer"
                                type="number"
                                required
                                hide-details
                            ></v-text-field>
                        </v-col>

                        <v-col cols="6">
                            <v-text-field
                                v-model="worker.phone"
                                label="Telefoonnummer"
                                type="tel"
                                required
                                hide-details
                            ></v-text-field>
                        </v-col>

                        <v-col cols="6">
                            <v-text-field
                                v-model="worker.first_name"
                                label="Voornaam"
                                type="text"
                                required
                                hide-details
                            ></v-text-field>
                        </v-col>

                        <v-col cols="6">
                            <v-text-field
                                v-model="worker.last_name"
                                label="Achternaam"
                                type="text"
                                required
                                hide-details
                            ></v-text-field>
                        </v-col>

                        <v-col cols="6">
                            <v-select
                                v-model="worker.agency"
                                label="Uitzendbureau"
                                :items="agencies"
                                required
                                hide-details
                            ></v-select>
                        </v-col>
                    </v-row>

                    <p
                        v-if="duplicateError"
                        class="text-left red--text ma-0 mt-2"
                    >
                        Uitzendkracht met werknemersnummer bestaat al.
                    </p>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        type="submit"
                        color="success"
                        text
                        :loading="creating"
                    >
                        Toevoegen
                    </v-btn>
                </v-card-actions>
            </form>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            dialog: false,
            worker: {
                id: null,
                first_name: null,
                last_name: null,
                phone: null,
                agency: null,
            },
            agencies: [
                { text: "Ago", value: "ago" },
                { text: "Synergie", value: "synergie" },
                { text: "Konvert", value: "konvert" },
                { text: "Deltaworx", value: "deltaworx" },
            ],
            creating: false,
            duplicateError: false,
        };
    },
    methods: {
        create() {
            if (this.worker.agency == null) return;

            this.duplicateError = false;
            this.creating = true;

            this.$store
                .dispatch("worker/create", this.worker)
                .then((worker) => {
                    this.$emit("worker", worker);

                    this.close();
                })
                .catch((error) => {
                    if (error.status == 409) this.duplicateError = true;
                })
                .finally(() => {
                    this.creating = false;
                });
        },
        open() {
            this.dialog = true;
        },
        close() {
            this.dialog = false;

            this.worker.id = null;
            this.worker.first_name = null;
            this.worker.last_name = null;
            this.worker.phone = null;
            this.worker.agency = null;
        },
    },
};
</script>

<style>
</style>