var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-none mr-2",attrs:{"small":"","depressed":""}},'v-btn',attrs,false),on),[_vm._v(" Details ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-btn',{staticClass:"dialog__close",attrs:{"icon":"","x-small":""},nativeOn:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-card-title',[_vm._v("Details")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"append-icon":"mdi-magnify","label":"Zoeken","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.details,"search":_vm.search,"loading":_vm.busWorkersLoading,"loading-text":"Bus details ophalen..."},scopedSlots:_vm._u([{key:"item.worker.agency",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.capitalize(item.worker.agency))+" ")]}},{key:"item.manual",fn:function(ref){
var item = ref.item;
return [(!item.manual)?_c('v-icon',{attrs:{"color":"success","small":""}},[_vm._v(" mdi-checkbox-marked-circle ")]):_c('v-icon',{attrs:{"color":"error","small":""}},[_vm._v("mdi-close-circle")])]}},{key:"item.confirmed",fn:function(ref){
var item = ref.item;
return [(item.confirmed)?_c('v-icon',{attrs:{"color":"success","small":""}},[_vm._v(" mdi-checkbox-marked-circle ")]):_c('v-icon',{attrs:{"color":"error","small":""}},[_vm._v("mdi-close-circle")])]}},{key:"item.exclude_retour",fn:function(ref){
var item = ref.item;
return [(item.exclude_retour)?_c('v-icon',{attrs:{"color":"error","small":""}},[_vm._v(" mdi-close-circle ")]):_c('v-icon',{attrs:{"color":"success","small":""}},[_vm._v(" mdi-checkbox-marked-circle ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('qr-dialog',{attrs:{"worker":item.worker}}),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openDeleteConfirmation(item.id)}}},[_c('v-icon',{attrs:{"color":"red","small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)})],1)],1),_c('delete-confirmation',{ref:"deleteDialog",attrs:{"title":"Bent u zeker?","text":"Bent u zeker dat u deze uitzendkracht wilt verwijderen van de bus?"},on:{"confirm":_vm.deleteWorkerFromBus}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }