const defaultOptions = {
	amount: 44,
	rows: 4
};

/**
 * Dynamically calculate bus layout.
 */
export default {
	install(Vue, options) {
		let userOptions = { ...defaultOptions, ...options };

		let busLayout = [];

		// Fill bus layout
		let row = [];
		for (let seat = 1; seat <= userOptions.amount; ++seat) {
			row.push(seat);

			if (seat % userOptions.rows == 0) {
				busLayout.push(row);
				row = [];
			}
		}

		// Transpose bus layout
		let transposedBusLayout = busLayout[0].map((_, colIndex) => busLayout.map(row => row[colIndex]));

		// 2D bus layout to 1D
		let oneD = [];
		for (let i = 0; i < transposedBusLayout.length; i++) {
			oneD = oneD.concat(transposedBusLayout[i]);
		}

		Vue.prototype.$transposedBusLayout = oneD;
	}
}