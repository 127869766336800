var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"workers"}},[_c('h2',{staticClass:"mb-2"},[_vm._v("Uitzendkrachten")]),_c('v-card',{attrs:{"elevation":"1"}},[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"append-icon":"mdi-magnify","label":"Zoeken","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"8"}},[_c('v-btn',{staticClass:"text-none mr-2",attrs:{"color":"success","depressed":""},on:{"click":function($event){return _vm.$refs.badgeDialog.open()}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"dark":""}},[_vm._v("mdi-qrcode")]),_vm._v(" Badges genereren ")],1),_c('v-btn',{staticClass:"text-none mr-2",attrs:{"color":"success","depressed":""},on:{"click":function($event){return _vm.$refs.createDialog.open()}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"dark":""}},[_vm._v("mdi-account-plus")]),_vm._v(" Toevoegen ")],1),_c('v-btn',{staticClass:"text-none",attrs:{"color":"primary","depressed":"","loading":_vm.exporting},on:{"click":function($event){return _vm.exportWorkers()}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"dark":"","small":""}},[_vm._v("mdi-download")]),_vm._v(" Exporteren ")],1)],1)],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.workers,"search":_vm.search,"loading":_vm.loadingWorkers,"loading-text":"Uitzendkrachten ophalen..."},scopedSlots:_vm._u([{key:"item.agency",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.capitalize(item.agency))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('qr-dialog',{attrs:{"worker":item}})]}},{key:"item.badge_exported",fn:function(ref){
var item = ref.item;
return [(item.badge_exported)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-checkbox-marked-circle")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close-circle")])]}},{key:"item.badge_activated",fn:function(ref){
var item = ref.item;
return [(item.badge_activated)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-checkbox-marked-circle")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close-circle")])]}}],null,true)})],1)],1),_c('create-dialog',{ref:"createDialog",on:{"worker":_vm.addWorker}}),_c('generate-badges-dialog',{ref:"badgeDialog",on:{"reload":_vm.reload}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }