import Vue from "vue";
import Vuex from "vuex";

import axios from "axios";

Vue.use(Vuex);

const planningModule = {
	namespaced: true,
	state: {},
	mutations: {},
	actions: {
		busOrder(context, date) {
			return new Promise((resolve, reject) => {
				axios.get("/api/planning/bus-order?date=" + date)
					.then((resp) => {
						resolve(resp.data);
					}).catch((error) => {
						reject(error);
					});
			});
		},
		busGrouping(context, { type, date }) {
			return new Promise((resolve, reject) => {
				axios.get("/api/planning/bus-grouping?type=" + type + "&date=" + date)
					.then((resp) => {
						resolve(resp.data);
					}).catch((error) => {
						reject(error);
					});
			});
		},
		busGroupingDetailed(context, bus_id) {
			return new Promise((resolve, reject) => {
				axios.get("/api/planning/bus-grouping/" + bus_id)
					.then((resp) => {
						resolve(resp.data);
					}).catch((error) => {
						reject(error);
					});
			});
		},
		exportBusOrder(context, date) {
			return new Promise((resolve, reject) => {
				axios.get("/api/planning/bus-order/export?date=" + date, {
					responseType: 'blob'
				}).then((resp) => {
					resolve(resp.data);
				}).catch((error) => {
					reject(error);
				});
			});
		},
		exportBusGrouping(context, { date, start }) {
			return new Promise((resolve, reject) => {
				axios.get("/api/planning/bus-grouping/export?date=" + date + "&start=" + start, {
					responseType: 'blob'
				}).then((resp) => {
					resolve(resp.data);
				}).catch((error) => {
					reject(error);
				});
			});
		},
		confirmBusOrder(context, date) {
			return new Promise((resolve, reject) => {
				axios.post("/api/planning/bus-order/confirm?date=" + date)
					.then((resp) => {
						resolve(resp.data);
					}).catch((error) => {
						reject(error);
					});
			});
		},
		confirmBusGrouping(context, { type, date }) {
			return new Promise((resolve, reject) => {
				axios.post("/api/planning/bus-grouping/confirm?type=" + type + "&date=" + date)
					.then((resp) => {
						resolve(resp.data);
					}).catch((error) => {
						reject(error);
					});
			});
		},
		import(context, data) {
			return new Promise((resolve, reject) => {
				axios.post("/api/planning/import", data, {
					headers: {
						"Content-Type": "multipart/form-data"
					}
				}).then((resp) => {
					resolve(resp.data);
				}).catch((error) => {
					reject(error.response);
				});
			});
		},
		orderBus(context, data) {
			return new Promise((resolve, reject) => {
				axios.post("/api/planning/bus", data).then((resp) => {
					resolve(resp.data);
				}).catch((error) => {
					reject(error.response);
				});
			});
		},
		addWorker(context, planning) {
			return new Promise((resolve, reject) => {
				axios.post("/api/planning/worker", planning).then((resp) => {
					resolve(resp.data);
				}).catch((error) => {
					reject(error.response);
				});
			});
		},
		deleteWorker(context, planningId) {
			return new Promise((resolve, reject) => {
				axios.delete("/api/planning/worker/" + planningId).then((resp) => {
					resolve(resp.data);
				}).catch((error) => {
					reject(error.response);
				});
			});
		},
		moveOperation(context, data) {
			return new Promise((resolve, reject) => {
				axios.post("/api/planning/bus-grouping/move", data).then((resp) => {
					resolve(resp.data);
				}).catch((error) => {
					reject(error.response);
				});
			});
		}
	},
	getters: {}
};

export default planningModule;
