<template>
    <div id="workers">
        <h2 class="mb-2">Uitzendkrachten</h2>

        <v-card elevation="1">
            <v-card-title>
                <v-row>
                    <v-col cols="4">
                        <v-text-field
                            v-model="search"
                            class="pt-0"
                            append-icon="mdi-magnify"
                            label="Zoeken"
                            hide-details
                        ></v-text-field>
                    </v-col>

                    <v-col cols="8" class="text-right">
                        <v-btn
                            class="text-none mr-2"
                            color="success"
                            @click="$refs.badgeDialog.open()"
                            depressed
                        >
                            <v-icon class="mr-2" dark>mdi-qrcode</v-icon>
                            Badges genereren
                        </v-btn>

                        <v-btn
                            class="text-none mr-2"
                            color="success"
                            @click="$refs.createDialog.open()"
                            depressed
                        >
                            <v-icon class="mr-2" dark>mdi-account-plus</v-icon>
                            Toevoegen
                        </v-btn>

                        <v-btn
                            class="text-none"
                            color="primary"
                            depressed
                            :loading="exporting"
                            @click="exportWorkers()"
                        >
                            <v-icon class="mr-2" dark small
                                >mdi-download</v-icon
                            >
                            Exporteren
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>

            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="workers"
                    :search="search"
                    :loading="loadingWorkers"
                    loading-text="Uitzendkrachten ophalen..."
                >
                    <template v-slot:[`item.agency`]="{ item }">
                        {{ capitalize(item.agency) }}
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <qr-dialog :worker="item" />

                        <!--
                        <v-btn icon @click="openDeleteConfirmation(item.id)">
                            <v-icon color="red" small> mdi-delete </v-icon>
                        </v-btn>
						-->
                    </template>

                    <template v-slot:[`item.badge_exported`]="{ item }">
                        <v-icon v-if="item.badge_exported" color="success"
                            >mdi-checkbox-marked-circle</v-icon
                        >
                        <v-icon v-else color="error">mdi-close-circle</v-icon>
                    </template>

                    <template v-slot:[`item.badge_activated`]="{ item }">
                        <v-icon v-if="item.badge_activated" color="success"
                            >mdi-checkbox-marked-circle</v-icon
                        >
                        <v-icon v-else color="error">mdi-close-circle</v-icon>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

        <create-dialog ref="createDialog" @worker="addWorker" />
        <generate-badges-dialog ref="badgeDialog" @reload="reload" />

        <!--
        <delete-confirmation
            ref="deleteDialog"
            title="Bent u zeker?"
            text="Bent u zeker dat u deze uitzendkracht wilt verwijderen?"
            @confirm="deleteWorker"
        />
		-->
    </div>
</template>

<script>
import CreateDialog from "@/components/workers/CreateDialog.vue";
import QrDialog from "@/components/workers/QrDialog.vue";
import GenerateBadgesDialog from "@/components/workers/GenerateBadgesDialog.vue";

export default {
    components: {
        CreateDialog,
        QrDialog,
        GenerateBadgesDialog,
    },
    data() {
        return {
            search: null,
            loadingWorkers: true,
            exporting: false,
            workers: [],
            headers: [
                { text: "Werknemersnummer", value: "id" },
                { text: "Voornaam", value: "first_name" },
                { text: "Achternaam", value: "last_name" },
                { text: "Telefoon", value: "phone" },
                { text: "Uitzendbureau", value: "agency" },
                {
                    text: "Geëxporteerd",
                    value: "badge_exported",
                    sortable: false,
                },
                {
                    text: "Geactiveerd",
                    value: "badge_activated",
                    sortable: false,
                },
                { text: "", value: "actions", sortable: false },
            ],
        };
    },
    mounted() {
        this.retrieveWorkers();
    },
    methods: {
        openDeleteConfirmation(id) {
            this.$refs.deleteDialog.setId(id);
            this.$refs.deleteDialog.open();
        },
        addWorker(newWorker) {
            this.workers.push(newWorker);
        },
        retrieveWorkers() {
            this.$store
                .dispatch("worker/index")
                .then((workers) => {
                    this.workers = workers;
                })
                .finally(() => {
                    this.loadingWorkers = false;
                });
        },
        exportWorkers() {
            this.exporting = true;

            this.$store
                .dispatch("worker/export")
                .then((blob) => {
                    var link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = "Uitzendkrachten.xlsx";
                    link.click();
                    URL.revokeObjectURL(link.href);
                })
                .finally(() => {
                    this.exporting = false;
                });
        },
        reload() {
            this.loadingWorkers = true;
            this.workers = [];

            this.retrieveWorkers();
        },
        /*
        deleteWorker(id) {
            this.$store.dispatch("worker/delete", id).finally(() => {
                this.workers.forEach(function (worker, index, workers) {
                    if (worker.id == id) workers.splice(index, 1);
                });

                this.$refs.deleteDialog.close();
            });
        },
		*/
        capitalize(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
    },
};
</script>
