<template>
    <v-container class="fill-height">
        <v-row>
            <v-col cols="12" align="center">
                <v-card class="pa-10 pt-6 pb-6" width="400">
                    <v-img
                        :src="require(`@/assets/logo.jpg`)"
                        class="mb-4 mx-auto"
                        width="100"
                    />

                    <form
                        id="reset-password"
                        v-on:submit.prevent="resetPassword"
                    >
                        <v-text-field
                            v-model="password"
                            label="Nieuw wachtwoord"
                            type="password"
                            required
                        ></v-text-field>

                        <v-text-field
                            v-model="passwordConfirm"
                            label="Bevestig nieuw wachtwoord"
                            type="password"
                            required
                            hide-details
                        ></v-text-field>

                        <!-- Message Success -->
                        <p
                            v-if="messageSuccess"
                            class="text-left green--text ma-0 mt-1"
                        >
                            <small>{{ messageSuccess }}</small>
                        </p>

                        <!-- Message Error -->
                        <p
                            v-if="messageError"
                            class="text-left red--text ma-0 mt-1"
                        >
                            <small>{{ messageError }}</small>
                        </p>

                        <v-btn
                            type="submit"
                            class="white--text text-none mt-8"
                            color="primary"
                            large
                            block
                            depressed
                            :loading="requesting"
                        >
                            Wachtwoord resetten
                        </v-btn>

                        <!-- Back to login -->
                        <p class="mt-1 text-left" style="font-size: 15px">
                            <router-link to="login">
                                Terug naar aanmelden
                            </router-link>
                        </p>
                    </form>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "ResetPassword",
    data() {
        return {
            token: null,
            email: null,
            password: null,
            passwordConfirm: null,
            messageSuccess: null,
            messageError: null,
            requesting: false,
        };
    },
    mounted() {
        this.token = this.$route.query.token;
        this.email = this.$route.query.email;

        if (!this.token || !this.email) this.$router.push({ name: "Login" });
    },
    methods: {
        resetPassword() {
            if (!this.passwordValidation()) return;

            this.requesting = true;

            this.$store
                .dispatch("resetPassword", {
                    token: this.token,
                    email: this.email,
                    password: this.password,
                    password_confirmation: this.passwordConfirm,
                })
                .then((resp) => {
                    this.messageSuccess = resp.message;
                })
                .catch((error) => {
                    if (error.errors.email)
                        this.messageError = error.errors.email[0];
                    else if (error.errors.password)
                        this.messageError = error.errors.password[0];
                })
                .finally(() => {
                    this.requesting = false;
                });

            return false;
        },
        passwordValidation() {
            this.messageSuccess = null;
            this.messageError = null;

            if (this.password != this.passwordConfirm)
                this.messageError = "Wachtwoorden komen niet overeen";

            if (this.password.length < 8)
                this.messageError =
                    "Wachtwoord moet langer zijn dan 8 karakters";

            return this.messageError == null;
        },
    },
};
</script>
