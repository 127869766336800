import Vue from "vue";
import Vuex from "vuex";

import authModule from "./auth.js";
import inspectorModule from "./inspector.js";
import workerModule from "./worker.js";
import planningModule from "./planning.js";
import validationModule from "./validation.js";
import badgeModule from "./badge.js";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		auth: authModule,
		inspector: inspectorModule,
		worker: workerModule,
		planning: planningModule,
		validation: validationModule,
		badge: badgeModule
	},
});
