<template>
    <div id="bus-report">
        <div id="bus-report-header">
            <h2 class="mb-2 mr-1 d-inline">{{ parsedDate }}</h2>

            <!-- Calendar -->
            <v-menu class="d-inline" absolute offset-y style="max-width: 600px">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mb-2" icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-calendar</v-icon>
                    </v-btn>
                </template>

                <v-date-picker
                    v-model="calendarDate"
                    @input="setDateFromCalendar()"
                ></v-date-picker>
            </v-menu>
        </div>

        <v-card class="mb-4" elevation="1">
            <v-card-title>
                <v-row>
                    <v-col cols="4">
                        <v-text-field
                            v-model="search"
                            class="pt-0"
                            append-icon="mdi-magnify"
                            label="Zoeken"
                            hide-details
                        ></v-text-field>
                    </v-col>

                    <v-col cols="8" class="text-right">
                        <v-btn
                            class="mr-2 text-none"
                            color="primary"
                            depressed
                            @click="exportLogs"
                            :loading="exporting"
                        >
                            <v-icon class="mr-2" dark small
                                >mdi-download</v-icon
                            >
                            Exporteren
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>

            <v-card-text>
                <bus-report-table :search="search" :date="calendarDate" />
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import BusReportTable from "@/components/report/BusReportTable.vue";

export default {
    components: { BusReportTable },
    data() {
        return {
            currentDate: new Date(),
            calendarDate: null,
            search: null,
            exporting: false,
        };
    },
    computed: {
        parsedDate() {
            const options = {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
            };

            let localeDate = this.currentDate.toLocaleDateString(
                "nl-BE",
                options
            );

            let [first, ...rest] = localeDate.split(" ");
            rest = rest.join(" ");

            localeDate =
                first.charAt(0).toUpperCase() + first.slice(1) + ", " + rest;

            return localeDate;
        },
    },
    mounted() {
        this.calendarDate = this.currentDate.toISOString().split("T")[0];
    },
    methods: {
        exportLogs() {
            this.exporting = true;

            this.$store
                .dispatch("validation/exportLogs", this.calendarDate)
                .then((blob) => {
                    var link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download =
                        "validation-log-" + this.calendarDate + ".xlsx";
                    link.click();
                    URL.revokeObjectURL(link.href);
                })
                .finally(() => {
                    this.exporting = false;
                });
        },
        setDateFromCalendar() {
            this.currentDate.setTime(new Date(this.calendarDate).getTime());

            // Force recompute (parsedDate)
            this.currentDate = new Date(this.currentDate.getTime());

            this.calendarDate = this.currentDate.toISOString().split("T")[0];
        },
    },
};
</script>

