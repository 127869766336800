<template>
    <v-container class="fill-height">
        <v-row>
            <v-col cols="12" align="center">
                <v-card class="pa-10 pt-6 pb-6" width="400">
                    <v-img
                        :src="require(`@/assets/logo.jpg`)"
                        class="mb-4 mx-auto"
                        width="100"
                    />

                    <form id="login" v-on:submit.prevent="signIn">
                        <v-text-field
                            v-model="username"
                            label="Gebruikersnaam"
                            type="text"
                            required
                        ></v-text-field>

                        <v-text-field
                            v-model="password"
                            label="Wachtwoord"
                            type="password"
                            hide-details
                            required
                        ></v-text-field>

                        <p
                            v-if="showError"
                            class="text-left red--text ma-0 mt-1"
                        >
                            <small>{{ error }}</small>
                        </p>

                        <v-btn
                            type="submit"
                            class="white--text text-none mt-8"
                            color="primary"
                            large
                            block
                            depressed
                            :loading="loggingIn"
                        >
                            Aanmelden
                        </v-btn>

                        <!-- Forgot password-->
                        <p class="mt-1 text-left" style="font-size: 15px">
                            <router-link to="wachtwoord-vergeten">
                                Wachtwoord vergeten?
                            </router-link>
                        </p>
                    </form>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "Login",
    data() {
        return {
            username: null,
            password: null,

            loggingIn: false,
            showError: false,
            error: null,
        };
    },
    methods: {
        signIn() {
            this.loggingIn = true;
            this.showError = false;

            this.$store
                .dispatch("signIn", {
                    username: this.username,
                    password: this.password,
                })
                .then(() => {
                    this.$store.dispatch("me");
                })
                .catch((error) => {
                    this.error = error.errors.username[0];
                    this.showError = true;
                })
                .finally(() => {
                    this.loggingIn = false;
                });

            return false;
        },
    },
};
</script>

<style>
a {
    text-decoration: none;
}
</style>
