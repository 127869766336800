<template>
    <v-dialog v-model="dialog" width="500">
        <v-card>
            <v-btn
                class="dialog__close"
                icon
                x-small
                @click.native="dialog = false"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-card-title> Badges </v-card-title>

            <form v-on:submit.prevent="generate">
                <v-card-text class="pt-0">
                    <v-alert type="info" class="mb-4" dense text>
                        We raden aan om max. 500 badges per keer te genereren!
                    </v-alert>

                    <v-file-input
                        v-model="file"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        label="Lijst uitzendkrachten"
                        required
                        hide-details="auto"
                        :error-messages="errorMessage"
                    ></v-file-input>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        type="submit"
                        color="success"
                        text
                        :loading="generating"
                    >
                        Genereren
                    </v-btn>
                </v-card-actions>
            </form>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            file: null,
            errorMessage: null,

            dialog: false,
            generating: false,
        };
    },
    methods: {
        generate() {
            if (!this.file) return;

            this.errorMessage = null;

            this.generating = true;

            let formData = new FormData();

            formData.append("file", this.file);

            this.$store
                .dispatch("badge/generate", formData)
                .then((data) => {
                    var fileURL = window.URL.createObjectURL(new Blob([data]));
                    var fileLink = document.createElement("a");

                    var today = new Date();
                    var dd = String(today.getDate()).padStart(2, "0");
                    var mm = String(today.getMonth() + 1).padStart(2, "0");
                    var yyyy = today.getFullYear();
                    today = dd + "-" + mm + "-" + yyyy;

                    fileLink.href = fileURL;
                    fileLink.setAttribute("download", `badges_${today}.pdf`);

                    fileLink.click();
                    URL.revokeObjectURL(fileLink.href);

                    this.$emit("reload");

                    this.file = null;
                    this.dialog = false;
                })
                .catch(() => {
                    this.errorMessage =
                        "Er ging iets mis. Zorg ervoor dat de tabelkopteksten correct zijn.";
                })
                .finally(() => {
                    this.generating = false;
                });

            this.$store.dispatch("badge/list", formData).then((blob) => {
                var fileURL = window.URL.createObjectURL(blob);
                var fileLink = document.createElement("a");

                fileLink.href = fileURL;
                fileLink.setAttribute("download", "smslijst.csv");

                fileLink.click();
                URL.revokeObjectURL(fileLink.href);
            });
        },
        open() {
            this.dialog = true;
        },
    },
};
</script>

<style>
</style>