<template>
    <v-container>
        <v-row>
            <v-col cols="12" align="center">
                <v-card width="400">
                    <v-card-title>Badge activeren</v-card-title>

                    <v-card-text>
                        <v-alert class="text-left" type="info" dense text
                            >Scan de QR code om de badge te activeren.</v-alert
                        >

                        <!-- Qrcode screen -->
                        <qrcode-stream
                            v-show="cameraInitialised"
                            @decode="onDecode"
                            @init="onInit"
                            :track="paintOutline"
                        ></qrcode-stream
                    ></v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <status-dialog ref="statusDialog" @close="validating = false" />
    </v-container>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";

import StatusDialog from "@/components/activator/StatusDialog.vue";

export default {
    name: "BadgeActivator",
    components: {
        QrcodeStream,
        StatusDialog,
    },
    data() {
        return {
            cameraInitialised: false,
            validating: false,
        };
    },
    methods: {
        async onInit(promise) {
            this.cameraInitialised = false;

            try {
                await promise;
            } catch (error) {
                if (error.name === "NotAllowedError") {
                    console.log("User denied camera access permisson");
                } else if (error.name === "NotFoundError") {
                    console.log("No suitable camera device installed");
                } else if (error.name === "NotSupportedError") {
                    console.log("Page is not served over HTTPS (or localhost)");
                } else if (error.name === "NotReadableError") {
                    console.log("Camera already in use");
                } else if (error.name === "StreamApiNotSupportedError") {
                    console.log("Browser seems to be lacking features");
                }
            } finally {
                this.cameraInitialised = true;
            }
        },
        onDecode(decodedString) {
            if (this.validating) return;

            this.validating = true;

            this.$refs.statusDialog.validate(decodedString);
        },
        paintOutline(detectedCodes, ctx) {
            for (const detectedCode of detectedCodes) {
                const [firstPoint, ...otherPoints] = detectedCode.cornerPoints;

                ctx.strokeStyle = "red";

                ctx.beginPath();
                ctx.moveTo(firstPoint.x, firstPoint.y);
                for (const { x, y } of otherPoints) {
                    ctx.lineTo(x, y);
                }
                ctx.lineTo(firstPoint.x, firstPoint.y);
                ctx.closePath();
                ctx.stroke();
            }
        },
    },
};
</script>
